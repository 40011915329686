import { gql } from '@apollo/client';
import { useMutation } from '@tanstack/react-query';
import { useAvContext } from '../../context/AvContextProvider';
import { UserPermissionType } from '../CustomDashboards/types';
import { DeliveryConfig, DeliveryMethodOptions, FileFormat } from './types';

export const FieldsToFilter = ['finding_count', 'active_finding_count', 'asset_count', 'active_asset_count', 'alert_count'];

export const getNewDeliveryConfig = (deliveryConfig?: DeliveryConfig, defaultFormat = FileFormat.CSV_FORMAT) => {
  const deliveryConfigByType = exportDetails => ({
    [DeliveryMethodOptions.Email]: {
      integrationId: null,
      title: exportDetails.title,
      message: exportDetails.message,
      recipients: exportDetails.recipients,
    },
    [DeliveryMethodOptions.S3]: {
      integrationId: exportDetails.integrationId,
    },
    [DeliveryMethodOptions.SLACK]: {
      integrationId: exportDetails.integrationId,
      title: exportDetails.title,
      message: exportDetails.message,
      channelType: exportDetails.channelType,
      channel: exportDetails.channel,
    },
    [DeliveryMethodOptions.TEAMS]: {
      integrationId: exportDetails.integrationId,
      title: exportDetails.title,
      message: exportDetails.message,
      channelId: exportDetails.channelId,
    },
  });
  if (!deliveryConfig) {
    return {
      integrationId: null,
      type: DeliveryMethodOptions.Email,
      recipients: [],
      title: '',
      message: '',
      attachmentMetadata: {
        fileFormat: defaultFormat,
      },
    };
  }
  return {
    type: deliveryConfig.type,
    ...deliveryConfigByType(deliveryConfig)[deliveryConfig.type],
    attachmentMetadata: {
      fileFormat: deliveryConfig.attachmentMetadata?.fileFormat || defaultFormat,
    },
    compressFile: !!deliveryConfig.compressFile,
  };
};

export const emailIntegrationType = { id: null, name: '', integrationType: DeliveryMethodOptions.Email };

export const integrationsToOptions = ({ name, id, integrationType }) => ({
  title: `${integrationType}${name ? ` - ${name}` : ''}`,
  value: id,
});

export const isAllowedEditPermissionOnRow = row => row.userPermissionType === UserPermissionType.editor;
export const isSomeRowViewerOnly = rows =>
  rows.filter(v => v).length && rows.some(({ userPermissionType }) => userPermissionType === UserPermissionType.viewer);

export const findIntegration = (availableIntegrationOptions, exportDetails) =>
  availableIntegrationOptions.find(({ id }) => id === exportDetails.deliveryConfig?.integrationId);

export const INACTIVE_INTEGRATION_FOR_SCHEDULED_REPORT = 'The schedule export integration destination is not active';

export const convertValueToURI = value => encodeURIComponent(JSON.stringify(value));
export const convertURIToValue = uri => JSON.parse(decodeURIComponent(uri));

const SET_ACTIVE = gql`
  mutation ($ruleSetId: String) {
    updateActiveRuleSetV2(ruleSetId: $ruleSetId)
  }
`;
const DEACTIVATE = gql`
  mutation ($ruleSetId: String) {
    deactivateRuleSet(ruleSetId: $ruleSetId)
  }
`;

export const useToggleActivateRuleSetMutation = ({ refetch }: { refetch: () => void }) => {
  const { api } = useAvContext();
  const activateMutation = useMutation({
    mutationFn: (ruleSetId: string): Promise<any> => api(SET_ACTIVE, { options: { ruleSetId } }),
    onSuccess: refetch,
  });
  const deactivateMutation = useMutation({
    mutationFn: (ruleSetId: string): Promise<any> => api(DEACTIVATE, { options: { ruleSetId } }),
    onSuccess: refetch,
  });

  return { activateMutation, deactivateMutation };
};
