import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Box, Button, debounce, Typography, useTheme } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import AvFilters from '../../components/AvFilters';
import { flex, PIE_PALETTES } from '../../components/AvThemeProvider';
import AvTooltip from '../../components/AvTooltip';
import { ItemWithLogo } from '../../components/ItemWithLogo';
import AssetList from '../../components/Widgets/AssetList';
import OverlappingAssetsBySource, { useSeries } from '../../components/Widgets/OverlappingAssetsBySource';
import Pie from '../../components/Widgets/VisualizationWidgets/AvPie';
import Widget from '../../components/Widgets/Widget';
import { useAvContext } from '../../context/AvContextProvider';
import { useCustomSearchParams } from '../../hooks/UseCustomSearchParams';
import { useWidgetData } from '../../utils/dashboardDataUtils';
import { deserializeSortItem, dotStyle, isNullOrUndefined } from '../../utils/Utils';
import { PieSubType } from '../CustomDashboards/types';
import { useSavedViews } from '../Entities/hooks';
import { useBuildWhereClause } from '../Tickets/hooks';
import { useSourceOptions } from './hooks';
import { defaultFilters, slicerWidth, viewScreenType } from './Utils';
import { ReactComponent as X } from '../../assets/X.svg';

const DashboardAssetCoveragePage: React.FC = () => {
  const {
    accountEntities: { aggProjs },
  } = useAvContext();
  const widgetData = useWidgetData();
  const theme = useTheme();
  const overlappingRef = useRef<HTMLElement>(null);
  const [widgetHeight, setWidgetHeight] = useState<number>();
  const [filtersOriginal, setFilters] = useCustomSearchParams({
    defaultFilter: defaultFilters,
    dateType: ['timeRange'],
    shouldBeArray: () => true,
  });

  const filters = useMemo(() => ({ ...filtersOriginal }), [filtersOriginal]);
  const { overlappingAssetsBySource } = filters;
  const seriesIndex = filters.seriesIndex?.[0];
  const selectedIndex = isNullOrUndefined(seriesIndex) ? -1 : parseInt(seriesIndex, 10);
  const page = +filters.tablePage[0];
  const orderBy = filters.orderBy
    .map(deserializeSortItem)
    .filter(({ property }) => !filters.headCells || filters.headCells.includes(property));

  const { SelectedView, SaveViewButtons, setSelectedViewChanged } = useSavedViews({
    viewScreenType,
    filters,
    setFilters,
    defaultFilters,
  });

  const onFilterChange = field => value => {
    setFilters({ ...filters, [field]: value || '', overlappingAssetsBySource: [], seriesIndex: [] });
    setSelectedViewChanged(true);
  };

  const { 'asset.source_names': sourceNamesFilters, ...filtersWithoutSources } = filters;
  const sourceFilterWhere = useBuildWhereClause({
    filters: {
      ...filtersWithoutSources,
      mappedSourceNames: [],
    },
    extra: sourceNamesFilters.length
      ? [`(name in(${sourceNamesFilters.map(s => `'${s}'`).join(',')}))`, `asset.state = 'ACTIVE'`]
      : [`asset.state = 'ACTIVE'`],
  });

  const { data: sourcesOptions = [] } = useSourceOptions(sourceFilterWhere);

  const dataSources = useMemo(
    () =>
      filters['asset.sourceNames']?.length
        ? sourcesOptions.filter(({ name }) => filters['asset.sourceNames'].includes(name))
        : sourcesOptions,
    [sourcesOptions, filters]
  );
  const series = useSeries(dataSources);

  const where = useBuildWhereClause({ filters, extra: [`asset.state = 'ACTIVE'`] });
  const whereSliced =
    selectedIndex === -1 ? [where] : [where, ...[series[selectedIndex]?.query(overlappingAssetsBySource[0])]].filter(d => d).join(' AND ');
  useLayoutEffect(() => {
    const updateSize = debounce(() => {
      setWidgetHeight(overlappingRef.current?.getBoundingClientRect().top);
    }, 100);
    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [overlappingRef.current]);

  const slicerStyle = {
    width: slicerWidth,
    borderRadius: '2px',
    zIndex: theme => theme.zIndex.drawer,
    backgroundColor: theme => theme.palette.colors.neutrals[150],
    overflow: 'auto',
    flexShrink: 0,
    '> div': { pb: '80px' },
    ':after': {
      content: '""',
      position: 'absolute',
      bottom: 24,
      background: theme => `linear-gradient(180deg, rgba(249, 249, 251, 0) 0%, ${theme.palette.colors.neutrals[150]} 77.08%)`,
      height: 110,
      width: slicerWidth - 20,
      pointerEvents: 'none',
    },
    ':before': {
      content: '""',
      position: 'absolute',
      bottom: 24,
      width: slicerWidth,
      top: widgetHeight,
      boxShadow: '0px -50px 50px rgba(18, 18, 27, 0.12)',
      pointerEvents: 'none',
    },
  };

  const renderSlicerLabel = (name, i) => (
    <Box sx={{ ...flex.itemsStart, gap: 1, flexWrap: 'wrap' }}>
      <Box sx={{ fontWeight: 600 }}>Assets = </Box>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        <Box sx={dotStyle(series[i].color)} />
        <ItemWithLogo type={name} variant="sourcesMapByName" logoWidth={16} hideText />
        <Box>
          {name} {series[i].name}
        </Box>
        <AvTooltip title="Remove slicer">
          <Button onClick={() => setFilters({ ...filters, overlappingAssetsBySource: [], seriesIndex: [] })}>
            <X />
          </Button>
        </AvTooltip>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ ...flex.col, width: '100%', gap: 2, overflow: 'auto' }}>
      <Typography variant="h3" sx={{ mb: 1 }}>
        Asset Coverage
      </Typography>
      <Box sx={{ ...flex.itemsCenter, gap: 2 }}>
        {SelectedView}
        <Box sx={{ flexGrow: 1 }} />
        {SaveViewButtons}
      </Box>
      <Box sx={{ ...flex.row, gap: 2 }}>
        <AvFilters
          filters={filtersOriginal}
          updateFilters={(field, val) => onFilterChange(field)(val)}
          setFilters={setFilters}
          activeProjName="uber_assets"
          fields={aggProjs.uber_assets.fieldList.INTERACTIVE}
        />
        {selectedIndex >= 0 && renderSlicerLabel(overlappingAssetsBySource[0], selectedIndex)}
      </Box>
      <Box
        ref={overlappingRef}
        sx={{
          ...flex.row,
          gap: 2,
          width: '100%',
          overflow: 'auto',
        }}>
        <Box sx={slicerStyle}>
          <Widget
            {...widgetData.overlappingAssetsBySource}
            hideTitle
            sql={widgetData.overlappingAssetsBySource.sql(where, dataSources || [])}
            height={230}
            background={theme.palette.colors.neutrals[150]}
            sx={{ p: 0, pt: 0, position: 'relative' }}
            queryOptions={{ placeholderData: keepPreviousData, enablbed: dataSources?.length || false }}>
            <OverlappingAssetsBySource
              setQueryProps={({ overlappingAssetsBySource, seriesIndex }) => {
                setSelectedViewChanged(true);
                setFilters({ ...filters, overlappingAssetsBySource, seriesIndex });
              }}
              dataSources={dataSources}
              filters={filters}
            />
          </Widget>
        </Box>
        <Box sx={{ ...flex.col, gap: 2, width: '100%', overflow: 'auto' }}>
          <Box sx={{ ...flex.row, gap: 2, width: '100%', [theme.breakpoints.down('laptop')]: flex.col }}>
            <Widget
              {...widgetData.uniqueAssetsByType}
              sql={widgetData.uniqueAssetsByType.sql(whereSliced)}
              metricKey="type"
              queryOptions={{ placeholderData: keepPreviousData }}
              sx={{ height: 265 }}>
              <Pie
                customPalette={PIE_PALETTES.blue}
                legendProps={{ variant: 'static', maxMetricWidth: 110, showShare: true }}
                type={PieSubType.Donut}
              />
            </Widget>
            <Widget
              {...widgetData.uniqueAssetsByOwner}
              sql={widgetData.uniqueAssetsByOwner.sql(whereSliced)}
              metricKey="owner"
              queryOptions={{ placeholderData: keepPreviousData }}
              sx={{ height: 265 }}>
              <Pie
                numOfItems={4}
                customPalette={PIE_PALETTES.yellow}
                legendProps={{ variant: 'static', maxMetricWidth: 110, showShare: true }}
                type={PieSubType.Donut}
              />
            </Widget>
          </Box>
          <Box sx={{ ...flex.row, gap: 2, width: '100%' }}>
            <Widget
              {...widgetData.assetList}
              sql={widgetData.assetList.sql(
                page,
                filters.headCells.length ? [...new Set([...filters.headCells, 'asset._key'])] : undefined,
                filters.headCells.length ? orderBy.filter(({ property }) => filters.headCells.includes(property)) : orderBy,
                whereSliced
              )}
              showTotalRows
              queryOptions={{ placeholderData: keepPreviousData }}
              sx={{ pb: 0 }}>
              <AssetList
                filtersOriginal={filters}
                setFilters={v => {
                  setSelectedViewChanged(true);
                  setFilters(v);
                }}
                totalsSQL={widgetData.assetList.totals(whereSliced)}
              />
            </Widget>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardAssetCoveragePage;
