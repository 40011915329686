import React, { Suspense } from 'react';
import { Box, Typography } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { flex } from './components/AvThemeProvider';
import { NotFound } from './components/ErrorBoundary';
import Loading from './components/Loading';
import AvAppBar from './components/newNavigation/AvAppBar';
import AvSidebar from './components/newNavigation/AvSideBar';
import { useAvContext } from './context/AvContextProvider';
import { useAppVersion } from './hooks/useAppVersion';
import { rebranding } from './rebranding';
import Assets from './routes/apps/Assets';
import Detections from './routes/apps/Detections';
import Explore from './routes/apps/Explore';
import Incidents from './routes/apps/Incidents';
import Platform from './routes/apps/Platform';
import Risk360 from './routes/apps/Risk360';
import Settings from './routes/apps/Settings';
import Vulnerabilities from './routes/apps/Vulnerabilities';
import { getAccountTokenFromLS } from './utils/auth.utils';
import avLazy from './utils/AvLazy';
import { getBranchPrefix } from './utils/router.utils';

const Welcome = avLazy(() => import('./views/Welcome'));

const loader = (
  <Box sx={{ ...flex.colCenter, width: '100%', height: '100%' }}>
    <Loading height={rebranding ? 56 : 200} />
  </Box>
);
export const loadingPage = (
  <main>
    <Box sx={{ ...flex.colCenter, gap: '50px' }}>
      <Loading height={rebranding ? 56 : 200} />
      <Typography variant="h4" sx={{ color: theme => theme.palette.colors.neutrals[500] }}>
        Authenticating...
      </Typography>
    </Box>
  </main>
);

export default function App() {
  const { isLoading, logout, isAuthenticated, accountId } = useAvContext();
  useAppVersion();
  if (!isAuthenticated) {
    if (isLoading) {
      if (!getAccountTokenFromLS(accountId)) {
        return loadingPage;
      }
    } else {
      logout(!!getAccountTokenFromLS(accountId));
      return loadingPage;
    }
  }
  const branchPrefix = getBranchPrefix() ? `${getBranchPrefix()}/` : '';
  return (
    <>
      {!!accountId && !isLoading ? (
        <>
          <AvAppBar />
          <AvSidebar />
        </>
      ) : undefined}
      <main>
        <Suspense fallback={loader}>
          <Routes>
            <Route path="">
              <Route
                path=":error-process-request"
                element={
                  <NotFound
                    text="Sorry, we are unable to proceed with your request"
                    buttonProps={{ children: 'Contact support', onClick: () => (window.location.href = 'mailto:support@avalor.io') }}
                  />
                }
              />
              <Route path={`${branchPrefix}:accountId`}>
                <Route path="" element={<Welcome />} />
                <Route path="*" element={<NotFound />} />
                {Vulnerabilities()}
                {Detections()}
                {Incidents()}
                {Platform()}
                {Explore()}
                {Settings()}
                {Risk360()}
                {Assets()}
              </Route>
            </Route>
          </Routes>
        </Suspense>
      </main>
    </>
  );
}
