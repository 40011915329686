import React from 'react';
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useAvContext } from '../../context/AvContextProvider.tsx';
import { formatPercent } from '../../utils/dashboardDataUtils';
import { dotStyle } from '../../utils/Utils';
import { flex } from '../AvThemeProvider.tsx';
import { Logo } from '../ItemWithLogo.tsx';

const getWidth = (a, b) => (a === 0 ? 0 : Math.max(1, (a / b) * 100));

function Interval({ color, size, sx }) {
  return (
    <Box
      sx={{
        height: 3,
        width: '100%',
        background: color,
        position: 'relative',
        transition: theme => theme.transitions.create('translate'),
        ':before, :after': {
          content: '""',
          height: size === 'small' ? 9 : 15,
          width: 3,
          borderRadius: '14px',
          background: 'inherit',
          position: 'absolute',
          top: size === 'small' ? '-3.5px' : '-6px',
        },
        ':after': { right: 0 },
        ...sx,
      }}
    />
  );
}
Interval.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['medium', 'small']),
  sx: PropTypes.shape(),
};
Interval.defaultProps = {
  size: 'medium',
  sx: {},
};

const overlappingMap = [
  {
    name: 'Tenable',
    findingCount: 47756,
    unique: 1003,
    overlapping: [
      { start: 0, count: 46000, sourceIndex: 1 },
      { start: 47756 - 1003 - 3000, count: 3000, sourceIndex: 2 },
    ],
  },
  {
    name: 'Lacework',
    findingCount: 46874,
    unique: 609,
    overlapping: [
      { start: 0, count: 45400, sourceIndex: 0 },
      { start: 46874 - 609 - 3000, count: 3000, sourceIndex: 2 },
    ],
  },
  {
    name: 'Snyk',
    findingCount: 6492,
    unique: 4024,
    overlapping: [
      { start: 0, count: 2200, sourceIndex: 0 },
      { start: 6492 - 4024 - 2000, count: 2000, sourceIndex: 1 },
    ],
  },
  {
    name: 'Data Theorem',
    findingCount: 2347,
    unique: 2347,
    overlapping: [],
  },
];

const STRETCH_FACTOR = 7;

export default function OverlappingDataBySource({ data: d }) {
  const {
    palette: { colors },
  } = useTheme();
  const {
    typeNameMap: {
      sourcesObj: { sourcesMapByName },
    },
  } = useAvContext();
  const data = overlappingMap || d;
  const maxFindings = Math.max(...data.map(({ findingCount }) => findingCount));

  const colorPalette = [
    { color: '#4EA9C3', background: '#E8F5F9' },
    { color: colors.negative[400], background: colors.negative[100] },
    { color: colors.primary[400], background: colors.primary[150] },
    { color: colors.yellow[500], background: colors.yellow[100] },
  ];

  return (
    <Box>
      <Box
        sx={{ ...flex.row, gap: 4, marginTop: 3, marginBottom: 6, color: colors.neutrals[500], '> div': { ...flex.itemsCenter, gap: 2 } }}>
        <Box>
          <Box sx={{ width: 48, height: 12, backgroundColor: colors.neutrals[350] }} />
          Recent Scan
        </Box>
        <Box>
          <Interval color={colors.neutrals[500]} size="small" sx={{ width: 45 }} />
          Overlapping Data
        </Box>
      </Box>
      <Box sx={{ ...flex.col, gap: 4 }}>
        {data.map(({ name, findingCount, unique, overlapping }, index) => (
          <Box
            key={name}
            sx={{
              ...flex.itemsCenter,
              gap: 3,
              py: 2,
              '> :first-of-type': { width: 170 },
              ':hover .interval-box > div': { translate: 0, opacity: 1 },
            }}>
            <div style={{ display: 'flex', gap: '8px', lineHeight: '40px' }}>
              <Logo {...sourcesMapByName[name === 'Tenable' ? 'Tenable Upload' : name]} width={40} />
              <Box
                component="span"
                sx={{
                  fontWeight: 600,
                  ':before': { content: '""', display: 'inline-block', mr: 1, ...dotStyle(colorPalette[index].color) },
                }}>
                {name}
              </Box>
            </div>
            <Box sx={{ ...flex.itemsCenter, gap: 2, pr: 3, fontSize: 13 }}>
              <Box
                sx={{
                  height: 24,
                  width: getWidth(findingCount, maxFindings) * STRETCH_FACTOR,
                  background: colorPalette[index].background,
                  position: 'relative',
                }}>
                <Box
                  className="interval-box"
                  sx={{ ...flex.col, gap: '12px', position: 'absolute', bottom: '32px', width: '100%', pointerEvents: 'none' }}>
                  {overlapping.map(({ start, count, sourceIndex }, index) => (
                    <Interval
                      key={sourceIndex}
                      color={colorPalette[sourceIndex].color}
                      size="small"
                      sx={{
                        width: getWidth(count, maxFindings) * STRETCH_FACTOR,
                        left: getWidth(start, maxFindings) * STRETCH_FACTOR,
                        translate: `0 ${(12 + 3) * (overlapping.length - index - 1) + 32 - 9}px`,
                        opacity: 0,
                      }}
                    />
                  ))}
                </Box>
                {findingCount - unique ? (
                  <Interval
                    color={colorPalette[index].color}
                    sx={{ position: 'absolute', top: '50%', width: getWidth(findingCount - unique, maxFindings) * STRETCH_FACTOR }}
                  />
                ) : null}
              </Box>
              <Box sx={{ fontWeight: 600, color: colors.neutrals[800] }}>{findingCount.toLocaleString()}</Box>
              <span style={{ color: colors.neutrals[600] }}>({formatPercent(getWidth(unique, findingCount).toFixed(1), 100)} Unique)</span>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

OverlappingDataBySource.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};
OverlappingDataBySource.defaultProps = {
  data: [],
};
