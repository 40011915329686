import { getEmptyFilterExpression } from '../../components/filters/Utils';
import {
  CommonPolicyFilterConfig,
  ConflictingValuesPolicyFilterConfig,
  ExecutableUnit,
  ExecutionRuleType,
  FilterType,
  PolicyCategory,
  PolicyCategoryId,
  PolicyMetadataType,
  PolicyType,
  TriggerType,
} from '../../types/executionRules.types';
import { Strategy } from '../../types/QueryObjectProto.types';
import { entityViewConfig } from '../../utils/entityViewConfig';
import { SEVERITY_LABELS } from '../../utils/severity.utils';

export const cmdbHygieneDefaultAssetPopulation = {
  and: {
    operands: [
      {
        expression: {
          fieldName: '',
          arrayCondition: {
            underlying: {
              fieldName: 'asset.source_names',
              stringCondition: {
                contains: 'ServiceNow',
              },
            },
            resolution: 'ALL',
          },
        },
      },
    ],
  },
};

export const getToolCoverageFilter = ({ sourcesNames, numOfDays }: { sourcesNames: string[]; numOfDays: number | undefined }) => ({
  not: {
    and: {
      operands: [
        {
          expression: {
            fieldName: '',
            arrayCondition: {
              resolution: 'ANY',
              underlying: {
                fieldName: 'asset.sources.source_names',
                stringCondition: {
                  contains: sourcesNames.join(),
                },
              },
            },
          },
        },
        {
          expression: {
            fieldName: 'asset.sources.last_seen',
            dateCondition: {
              relative: {
                unit: 'DAYS',
                value: numOfDays,
                periodBehaviour: 'LAST',
              },
            },
          },
        },
      ],
    },
  },
});

// returns a Cmdb filter structure from the three selected fields
export const getConflictingValuesFilter = ({
  fieldName = '',
  uniqueValues = 0,
  excludedValues = [],
}: {
  fieldName?: string;
  uniqueValues?: number;
  excludedValues?: string[];
}) => ({
  filterType: FilterType.CONFLICTING_VALUES,
  fieldName,
  uniqueValues,
  excludedValues,
});

export const cmdbSimpleFilterDefaults = {
  [FilterType.MISSING_FIELD]: {
    filterType: FilterType.MISSING_FIELD,
    filter: {
      or: {
        operands: [
          {
            expression: {
              fieldName: 'asset.owner_id',
              stringCondition: {
                empty: {},
              },
            },
          },
          {
            expression: {
              fieldName: 'asset.owner_id',
              stringCondition: {
                equals: 'Unknown',
              },
            },
          },
        ],
      },
    },
  },
  [FilterType.CONFLICTING_VALUES]: {
    filterType: FilterType.CONFLICTING_VALUES,
    fieldName: 'asset.sources.owner_id',
    uniqueValues: 1,
    excludedValues: [],
  },
};

export const defaultPolicyMetadataProps: PolicyMetadataType = {
  policy_name: '',
  policy_description: '',
  severity: SEVERITY_LABELS.MEDIUM,
  severity_score: 5,
  type: PolicyCategory[PolicyCategoryId.NONE],
};
export const defaultExecutableUnitProps: ExecutableUnit = {
  executionConfig: {
    fields: [],
    evalStrategy: Strategy.CEL,
  },
  primaryQuery: {
    evaluatePerRow: true,
    query: {
      name: '',
      queryObject: {
        sourceProjection: { name: entityViewConfig.Asset.projectionName, builtIn: true },
        dims: [],
        metrics: [],
      },
    },
  },
  secondaryQueries: [],
};

export const defaultPolicyTypeProps: PolicyType = {
  executableUnits: [defaultExecutableUnitProps],
  preCheckUnits: [],
  type: ExecutionRuleType.ASSET_POLICY,
  metadata: defaultPolicyMetadataProps,
  executionRunConfiguration: {
    trigger: {
      type: TriggerType.SCHEDULED,
      value: '0 0 * * *',
    },
    outcomes: [],
    active: true,
  },
  clientConfig: {
    assetPopulationFilter: {
      and: {
        operands: [
          {
            expression: {
              fieldName: 'asset.type',
              stringCondition: {
                contains: '',
              },
            },
          },
          {
            expression: {
              arrayCondition: {
                underlying: {
                  fieldName: 'asset.source_names',
                  stringCondition: {
                    contains: '',
                  },
                },
                resolution: 'ANY',
              },
            },
          },
        ],
      },
    },
    policyScenario: {
      filterType: FilterType.ADVANCED,
      filter: getEmptyFilterExpression(),
    },
  },
};

export const categoryToDefaultFilterMap = {
  [PolicyCategoryId.CMDB]: {
    assetPopulationFilter: cmdbHygieneDefaultAssetPopulation,
    policyScenario: cmdbSimpleFilterDefaults[FilterType.MISSING_FIELD],
  },
  [PolicyCategoryId.TOOL_COVERAGE]: {
    assetPopulationFilter: defaultPolicyTypeProps.clientConfig.assetPopulationFilter,
    policyScenario: {
      filterType: FilterType.TOOL_COVERAGE,
      filter: {
        or: {
          operands: [
            getToolCoverageFilter({ numOfDays: 7, sourcesNames: [''] }),
            {
              expression: {
                fieldName: 'asset.has_edr',
                boolCondition: {
                  equals: false,
                },
              },
            },
          ],
        },
      },
    },
  },
};

export const getDistinctValuesObjects = (newData: PolicyType) => {
  const { fieldName, uniqueValues, excludedValues } = newData.clientConfig.policyScenario as ConflictingValuesPolicyFilterConfig;
  return { fieldName, uniqueValues, excludedValues };
};
const isCommonPolicyFilterConfig = (
  policyScenario: CommonPolicyFilterConfig | ConflictingValuesPolicyFilterConfig
): policyScenario is CommonPolicyFilterConfig => policyScenario.filterType !== 'CONFLICTING_VALUES';

export const clearEmptyPolicyScenarioFilter = (newData: PolicyType) =>
  isCommonPolicyFilterConfig(newData.clientConfig.policyScenario)
    ? {
        ...newData,
        clientConfig: {
          ...newData.clientConfig,
          policyScenario: {
            ...newData.clientConfig.policyScenario,
            filter:
              newData.clientConfig.policyScenario.filter.and?.operands[0].expression?.fieldName === null
                ? null
                : newData.clientConfig.policyScenario.filter,
          },
        },
      }
    : newData;
