import React from 'react';
import { Box, useTheme } from '@mui/material';
import AvAddOperandButton from '../../../components/AvAddOperandButton';
import { flex } from '../../../components/AvThemeProvider';
import DataSourceSelect from '../../../components/DataSourceSelect';
import FieldFilterBox from '../../../components/filters/FieldFilterBox';
import { boxWidth, getEmptyFilterExpression } from '../../../components/filters/Utils';
import TextInput from '../../../components/TextInput';
import { useAvContext } from '../../../context/AvContextProvider';
import { CommonPolicyFilterConfig, FilterType, PolicyType } from '../../../types/executionRules.types';
import { setNestedValue } from '../../../utils/Utils';
import { getToolCoverageFilter } from '../utils';

const ToolCoverageSimpleFilter = ({ setNewData, newData }: { setNewData: (newData: PolicyType) => void; newData: PolicyType }) => {
  const { palette } = useTheme();
  const {
    accountEntities: { aggProjs },
    typeNameMap: {
      sourcesObj: { sourceOptions },
    },
  } = useAvContext();
  const { filterType, filter } = newData.clientConfig.policyScenario as CommonPolicyFilterConfig;
  const assetFields = aggProjs.uber_assets.fields;
  const firstFilter = filter.and?.operands[0] || filter.or?.operands[0];
  const secondFilter = filter.and?.operands[1] || filter.or?.operands[1] || getEmptyFilterExpression();
  const borderColor = filter.and ? palette.colors.primary[500] : palette.colors.yellow[500];
  const sourcesArrayString = firstFilter?.not?.and?.operands[0]?.expression?.arrayCondition?.underlying.stringCondition?.contains;
  const sourcesArray = sourcesArrayString && sourcesArrayString?.length > 0 ? sourcesArrayString.split(',') : undefined;
  const numOfDays = firstFilter?.not?.and?.operands[1]?.expression?.dateCondition?.relative?.value;

  return (
    <Box>
      {filterType === FilterType.TOOL_COVERAGE && (
        <Box sx={{ ...flex.colItemsStart }}>
          <Box
            sx={{
              ...flex.colItemsStart,
              gap: 1,
              mt: 1,
              padding: 1.5,
              border: `1px solid ${palette.colors.neutrals[300]}`,
              borderLeft: `2px solid ${borderColor}`,
              borderRadius: 1,
              width: 850,
            }}>
            Asset was not discovered in the following sources:
            <Box sx={{ ...flex.justifyStartCenter, width: '100%', gap: 1 }}>
              <DataSourceSelect
                onChange={newSources => {
                  setNewData(
                    setNestedValue(
                      `clientConfig.policyScenario.filter.${filter.and ? 'and' : 'or'}.operands.0`,
                      newData,
                      getToolCoverageFilter({
                        sourcesNames: newSources,
                        numOfDays,
                      })
                    )
                  );
                }}
                options={sourceOptions}
                filterValue={sourcesArray}
                variant="input"
                label=""
                selectProps={{ placeholder: 'Select...' }}
              />
              <Box>in the last</Box>
              <TextInput
                inputMode="numeric"
                sx={{ width: 90 }}
                size="small"
                value={numOfDays}
                onChange={newVal => {
                  setNewData(
                    setNestedValue(
                      `clientConfig.policyScenario.filter.${filter.and ? 'and' : 'or'}.operands.0`,
                      newData,
                      getToolCoverageFilter({
                        sourcesNames: sourcesArray || [''],
                        numOfDays: newVal,
                      })
                    )
                  );
                }}
              />
              <Box>day(s)</Box>
            </Box>
          </Box>
          <Box
            sx={{
              ...flex.itemsStart,
              my: '-4px',
              ml: `${boxWidth / 4}px`,
            }}>
            <AvAddOperandButton
              style={{ translate: '-50%' }}
              size="small"
              operator={filter.and ? 'and' : 'or'}
              onClick={() =>
                setNewData(
                  setNestedValue(
                    'clientConfig.policyScenario.filter',
                    newData,
                    filter.and ? { or: { operands: [firstFilter, secondFilter] } } : { and: { operands: [firstFilter, secondFilter] } }
                  )
                )
              }
            />
          </Box>
          <FieldFilterBox
            setFilter={exp =>
              setNewData(setNestedValue(`clientConfig.policyScenario.filter.${filter.and ? 'and' : 'or'}.operands.1`, newData, exp))
            }
            fields={assetFields}
            isVertical={false}
            addLogicalExpression={() => {}}
            filter={secondFilter}
            index={0}
            level={0}
            onDelete={() => {}}
            rootOperator={filter.and ? 'and' : 'or'}
            size="small"
            customSx={{ borderLeft: `2px solid ${borderColor}` }}
            fieldDescription="Asset missing coverage indication"
            showDeleteAddFilter={false}
          />
        </Box>
      )}
    </Box>
  );
};
export default ToolCoverageSimpleFilter;
