import React, { ReactElement } from 'react';
import { Box, LinearProgress, Skeleton, Typography, TypographyVariant, useTheme } from '@mui/material';
import useElementOnScreen from '../../hooks/useElementOnScreen';
import useQuerySql from '../../hooks/useQuerySql';
import { dotStyle, emptyArray, isNullOrUndefined } from '../../utils/Utils';
import AvTag from '../AvTag';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import { ErrorBoundary } from '../ErrorBoundary';
import { ErrorLoadingWidget, HistoricDataIndicator, NoDataFoundWidget, WidgetTitle } from './layout.components';
import { ReactComponent as FilterIcon } from '../../assets/Filter.svg';

const filterIcon = <FilterIcon strokeWidth={0.5} style={{ width: 16, height: 16 }} />;

export const chartStyle = {
  ...flex.col,
  flex: 1,
  position: 'relative',
  overflow: 'auto',
  maxHeight: '100%',
  '.recharts-surface': {
    pointerEvents: 'auto',
    height: 'auto',
    width: 'auto',
    'text:not(.custom-chart-text)': {
      fontSize: '12px',
      fill: theme => theme.palette.colors.neutrals[500],
    },
    // '.recharts-yAxis': {
    //   transform: 'translate(15px,0)',
    // },
    '.recharts-xAxis': {
      transform: 'translate(0,5px)',
    },
  },
};

interface SliceIndicatorProps {
  data?: any[];
  isSlicer?: boolean;
  hideText?: boolean;
}

function SliceIndicator({ data = emptyArray, isSlicer, hideText = false }: SliceIndicatorProps) {
  const theme = useTheme();
  if (isNullOrUndefined(isSlicer)) {
    return null;
  }
  const color = isSlicer ? '#fff' : theme.palette.colors.primary[500];
  const tooltipContent = (
    <Box sx={{ ...flex.colItemsStart }}>
      {data.map(item => (
        <Box key={item.value} sx={{ ...flex.itemsCenter, gap: 1 }}>
          <Box sx={dotStyle(item.color)} />
          <Box>{item.value}</Box>
        </Box>
      ))}
    </Box>
  );
  return (
    <AvTooltip title={isSlicer ? tooltipContent : hideText ? 'Sliced' : ''}>
      <div>
        <AvTag
          text={
            <>
              {filterIcon}
              {!hideText && (isSlicer ? 'Slicer' : 'Sliced')}
            </>
          }
          color={color}
          bg={isSlicer ? theme.palette.colors.primary[500] : theme.palette.colors.primary[150]}
          sx={{ ...flex.itemsCenter, gap: '6px', borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        />
      </div>
    </AvTooltip>
  );
}

interface WidgetProps {
  children: ReactElement;
  title?: string;
  titleVariant?: TypographyVariant;
  sql: string | string[];
  metricKey?: string;
  metricName?: string;
  valLabel?: string;
  valKey?: string;
  height?: string | number;
  width?: string | number;
  hideTitle?: boolean;
  titleMargin?: number;
  background?: string;
  transformData?: (d: any) => any;
  onError?: () => void;
  sx?: any;
  queryOptions?: any;
  sliceProps?: any;
  historicalDataProps?: any;
  errorProps?: any;
  muteErrors?: boolean;
  noDataProps?: any;
  dateFormat?: string;
  showTotalRows?: boolean;
}

function Widget({
  children,
  title = '',
  sql,
  metricKey,
  metricName,
  valLabel,
  valKey,
  height = 'auto',
  width = '100%',
  hideTitle,
  titleMargin = 2,
  titleVariant,
  background,
  transformData,
  onError,
  sx,
  queryOptions,
  sliceProps,
  historicalDataProps,
  errorProps,
  muteErrors,
  noDataProps,
  dateFormat = 'MMM',
  showTotalRows,
}: WidgetProps) {
  const { ref: visibilityRef, isVisible } = useElementOnScreen({});
  const {
    data,
    totals,
    isLoading,
    isRefetching,
    errors = [],
    isPreviousData,
  } = useQuerySql({
    key: title,
    sql,
    onSuccess: transformData,
    onError,
    muteErrors,
    showTotals: showTotalRows,
    options: { ...queryOptions, ...(!isVisible && { enabled: false }) },
  });
  const loading = isLoading && !isPreviousData;
  const isError = errorProps?.forceShowError || errors.length;
  const shouldRender = !sql || (!loading && Object.keys(data || {}).length > 0 && !isError);
  const isNoData = isVisible && !isLoading && !isError && data?.length === 0;
  const isErrorQuery = !!sql && !!isError;
  const isSkeleton = isNullOrUndefined(data) || isLoading;

  return (
    <Box
      ref={visibilityRef}
      sx={{
        ...chartStyle,
        width,
        minHeight: isLoading ? Number(height) || 'auto' : height,
        p: background === 'transparent' ? 0 : 3,
        pt: background === 'transparent' ? 0 : 2,
        background: theme => background || theme.palette.white.main,
        ...sx,
        ...(isLoading && { pb: 3 }),
      }}>
      <ErrorBoundary fallbackComponent={<ErrorLoadingWidget {...errorProps} />}>
        {title && !hideTitle ? (
          titleVariant ? (
            <Typography sx={{ mb: titleMargin }} variant={titleVariant}>
              {title}
            </Typography>
          ) : (
            <WidgetTitle marginBottom={titleMargin}>{title}</WidgetTitle>
          )
        ) : null}
        {!isErrorQuery && isSkeleton && (
          <Skeleton animation="wave" variant="rectangular" sx={{ background: theme => theme.palette.colors.neutrals[300], flex: 1 }} />
        )}
        {isNoData && <NoDataFoundWidget showImage {...noDataProps} />}
        {isErrorQuery && <ErrorLoadingWidget {...errorProps} />}
        {!isSkeleton && isRefetching && <LinearProgress sx={{ top: 0, left: 0, position: 'absolute', width: '100%', height: '2px' }} />}
        <Box
          sx={{
            ...flex.row,
            position: 'absolute',
            right: !isNullOrUndefined(sliceProps?.isSlicer) ? 0 : '24px',
            top: '20px',
            flexDirection: 'row-reverse',
            gap: 0.5,
          }}>
          {sliceProps && <SliceIndicator {...sliceProps} />}
          {historicalDataProps && <HistoricDataIndicator {...historicalDataProps} />}
        </Box>
        {shouldRender &&
          React.cloneElement(children, {
            ...(sql && { data }),
            ...(showTotalRows && { totals }),
            isLoading: isLoading || isRefetching,
            metricKey,
            metricName,
            valLabel,
            valKey,
            sql,
            dateFormat,
          })}
      </ErrorBoundary>
    </Box>
  );
}

export default Widget;
