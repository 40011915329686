// eslint-disable-next-line import/prefer-default-export
import envVariables from '../shared/projectEnvVariables';

export const isExportMode = () => envVariables.VITE_ENV === 'export';

export const getExporterWorkflowMetadata = () => {
  const workflowId = localStorage.getItem('export_workflowId');
  const runId = localStorage.getItem('export_runId');
  return runId || workflowId ? { workflowId, runId, name: 'ExportDashboardWorkflow' } : {};
};
