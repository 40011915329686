import React, { useRef, useState } from 'react';
import { Box, ClickAwayListener, Paper, Popper, useTheme } from '@mui/material';
import { useAvContext } from '../../context/AvContextProvider';
import { rebranding } from '../../rebranding';
import { iconSize } from '../../utils/Utils';
import AvItemWithLogoGroup from '../AvItemWithLogoGroup';
import { flex } from '../AvThemeProvider';
import AvTooltip from '../AvTooltip';
import DataSourceSelect from '../DataSourceSelect';
import { ErrorTooltip } from '../InputTooltipWrapper';
import SwitchToggle from '../SwitchToggle';
import { ReactComponent as ArrowDown } from '../../assets/Arrow Down.svg';
import { ReactComponent as Circle } from '../../assets/Circle.svg';
import { ReactComponent as Warning } from '../../assets/colorful/Info Full.svg';
import { ReactComponent as WarningNew } from '../../assets/colorful2/Info Full.svg';
import { ReactComponent as QuestionFull } from '../../assets/QuestionFull.svg';
import { ReactComponent as Unified } from '../../assets/Unified.svg';

const popupIcon = <ArrowDown style={iconSize(16)} />;
const questionFull = <QuestionFull />;
const unified = <Unified style={iconSize(20)} />;
const circle = <Circle style={iconSize(20)} />;
const warning = rebranding ? <WarningNew /> : <Warning />;

interface UnifiedIngressToggleProps {
  isIngressExp: boolean;
  onChangeIngressExp: () => void;
  onChangeSources: (source) => void;
  sourcesValue: string[];
}

const UnifiedIngressToggle: React.FC<UnifiedIngressToggleProps> = ({ isIngressExp, onChangeIngressExp, onChangeSources, sourcesValue }) => {
  const { palette } = useTheme();

  const {
    typeNameMap: {
      sourcesObj: { sourceOptions },
    },
  } = useAvContext();

  const [isPopperOpen, setIsPopperOpen] = useState<boolean>(false);
  const [noSourcesSelectedError, setNoSourcesSelectedError] = useState<boolean>(false);
  const boxRef = useRef();
  const warningIcon = <Box sx={{ ...flex.justifyEndCenter, ml: 9.5 }}>{warning}</Box>;

  return (
    <Box sx={{ ...flex.itemsCenter }}>
      <Box
        onClick={() => setIsPopperOpen(prev => !prev)}
        ref={boxRef}
        sx={{
          ...flex.itemsCenter,
          border: `1px solid ${palette.colors.neutrals[400]}`,
          borderRadius: 2,
          pr: 1,
          height: 32,
          width: 120,
        }}>
        <Box
          sx={{
            ...flex.center,
            backgroundColor: palette.colors.neutrals[500],
            border: `1px solid ${palette.colors.neutrals[500]}`,
            borderBottomLeftRadius: 7,
            borderTopLeftRadius: 7,
            height: '100%',
            width: 40,
            mr: 0.5,
            color: palette.colors.neutrals[100],
          }}>
          {isIngressExp ? circle : unified}
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
          }}>
          {isIngressExp ? (
            <AvItemWithLogoGroup variant="sourcesMapByName" items={sourcesValue} size="collapsed" />
          ) : (
            <Box sx={{ ml: 0.5 }}>Unified</Box>
          )}
        </Box>
        <Box
          sx={{
            ...flex.justifyEndCenter,
            width: 16,
          }}>
          {popupIcon}
        </Box>
      </Box>
      <Popper open={isPopperOpen} anchorEl={boxRef.current} placement="bottom-start" sx={{ zIndex: 1500 }}>
        <ClickAwayListener
          onClickAway={() => {
            if (sourcesValue.length === 0 && isIngressExp) {
              setNoSourcesSelectedError(true);
            } else {
              setIsPopperOpen(prev => !prev);
            }
          }}>
          <Paper
            sx={{
              ...flex.colItemsStart,
              backgroundColor: `${palette.colors.neutrals[100]}`,
              padding: 1,
              width: 300,
              borderRadius: 1,
              gap: 1.5,
            }}>
            <Box
              sx={{
                backgroundColor: palette.colors.neutrals[200],
                px: 1,
                py: 0.5,
                borderRadius: 1,
                ...flex.justifyStartCenter,
                gap: 1,
                width: '100%',
                fontSize: '12px',
              }}>
              {questionFull}
              Explanation of what unified means
            </Box>
            <Box sx={{ ...flex.justifyStartCenter }}>
              <SwitchToggle value={isIngressExp} onChange={onChangeIngressExp} sx={{ marginRight: 2, mb: 0.5 }} /> Use Original Source
              {isIngressExp &&
                (noSourcesSelectedError ? (
                  <ErrorTooltip error="You must choose at least one source" open={noSourcesSelectedError}>
                    {warningIcon}
                  </ErrorTooltip>
                ) : (
                  <AvTooltip title="You must choose at least one source">{warningIcon} </AvTooltip>
                ))}
            </Box>
            {isIngressExp && (
              <Box
                sx={{
                  width: 284,
                  borderRadius: 1,
                }}>
                <DataSourceSelect
                  size="small"
                  onChange={val => {
                    onChangeSources(val);
                    setNoSourcesSelectedError(false);
                  }}
                  options={sourceOptions}
                  filterValue={sourcesValue}
                  selectProps={{
                    showOnlyAutoComplete: true,
                    muiProps: { componentsProps: { paper: { style: { boxShadow: 'unset', height: '200px' } } } },
                  }}
                />
              </Box>
            )}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default UnifiedIngressToggle;
