import React from 'react';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useAvContext } from '../context/AvContextProvider';
import { rebranding } from '../rebranding';
import { APP_PATHS, PAGE_PATHS, PUBLIC_APPS } from '../types';
import { useGetStatuses } from '../views/Settings/TicketStatuses/hooks';
import { flex } from './AvThemeProvider';
import { ErrorBoundary } from './ErrorBoundary';
import Loading from './Loading';
import { useEnabledAppsOptions } from './newNavigation/hooks';

interface Props {
  isAllowed?: boolean;
  children?: any;
}
const ProtectedRoute: React.FC<Props> = ({ isAllowed, children }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading: isLoadingStatuses } = useGetStatuses();
  const { isLoading, user, selectedApp, getPathName } = useAvContext();
  const enabledApps = useEnabledAppsOptions();
  const appPermission = enabledApps.some(({ id }) => id === selectedApp) || PUBLIC_APPS.includes(selectedApp);
  if (
    ([APP_PATHS.VULNERABILITIES, APP_PATHS.DETECTIONS, APP_PATHS.INCIDENTS, APP_PATHS.RISK360, APP_PATHS.CAASM].includes(selectedApp) &&
      isLoadingStatuses) ||
    isLoading ||
    !user.roleId ||
    !selectedApp ||
    selectedApp === ''
  ) {
    return (
      <Box sx={flex.colCenter}>
        <Loading height={rebranding ? 56 : 200} />
      </Box>
    );
  }
  if (!isAllowed || !appPermission) {
    enqueueSnackbar("You don't have access to this page", { variant: 'warning' });
    if (!appPermission) {
      navigate(getPathName(PAGE_PATHS.SOURCES, '', APP_PATHS.PLATFORM));
    } else {
      navigate('/');
    }
    return <Box />;
  }
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default ProtectedRoute;
