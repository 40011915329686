import React from 'react';
import { ItemWithLogo } from './ItemWithLogo';
import Select from './Select';

interface DataSourceSelectProps {
  filterValue?: string[];
  getValueFunc?: (option: any) => string;
  onChange: (value: string[]) => void;
  options: { name: string }[];
  selectProps?: object /* SelectProps */;
  size?: string;
  label?: string;
  variant?: string;
}

function DataSourceSelect({
  filterValue = [],
  getValueFunc = ({ name }) => name,
  onChange,
  options,
  selectProps = {},
  size = 'small',
  label = 'Source',
  variant = 'filter',
}: DataSourceSelectProps) {
  return (
    <Select
      size={size}
      label={label}
      value={filterValue}
      onChange={val => onChange(val)}
      options={options}
      renderOption={({ name }) => <ItemWithLogo variant="sourcesMapByName" type={name} />}
      getLabelFunc={({ name }) => name}
      getValueFunc={getValueFunc}
      isMultiple
      variant={variant}
      {...selectProps}
    />
  );
}

export default DataSourceSelect;
