import React, { useMemo } from 'react';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { keepPreviousData } from '@tanstack/react-query';
import { flex } from '../../../components/AvThemeProvider';
import NewExpressionBuilder from '../../../components/filters/NewExpressionBuilder';
import Select from '../../../components/Select';
import TextInput from '../../../components/TextInput';
import { useAvContext } from '../../../context/AvContextProvider';
import { FieldListObject } from '../../../context/context.type';
import useQueryObject from '../../../hooks/useQueryObjectSql';
import { FilterType, PolicyType } from '../../../types/executionRules.types';
import { Filter } from '../../../types/filter.types';
import { setNestedValue } from '../../../utils/Utils';
import { DwQueryRequest } from '../../CustomDashboards/types';
import { cmdbSimpleFilterDefaults, getConflictingValuesFilter, getDistinctValuesObjects } from '../utils';
import PoliciesToggleButton from './PoliciesToggleButton';

const CmdbSimpleFilter = ({
  setNewData,
  newData,
  filterOptions,
  projName,
}: {
  setNewData: (newData: PolicyType) => void;
  newData: PolicyType;
  filterOptions: FieldListObject[];
  projName: string;
}) => {
  const {
    accountEntities: { aggProjs },
  } = useAvContext();
  const { palette } = useTheme();
  const cmdbObject = newData.clientConfig.policyScenario;

  const conflictingValuesObj = getDistinctValuesObjects(newData);

  const selectCommonProps = width => ({
    size: 'small',
    style: {
      '.MuiOutlinedInput-notchedOutline, .MuiInputBase-root': {
        width,
      },
    },
    variant: 'input',
  });
  const boxSx = {
    ...flex.itemsCenter,
    gap: 2,
    mt: 1,
    padding: 1.5,
    border: `1px solid ${palette.colors.neutrals[300]}`,
    borderRadius: 1,
  };

  const optionsQueryObject: DwQueryRequest = {
    projectionId: { name: projName, builtIn: true },
    select: {
      dims: [{ name: conflictingValuesObj?.fieldName }],
      metrics: [],
    },
    distinct: true,
    sorting: [],
  };

  const { data: distinctOptions = [] } = useQueryObject({
    muteErrors: true,
    queryObject: optionsQueryObject,
    options: {
      placeholderData: keepPreviousData,
      enabled: !!optionsQueryObject && cmdbObject.filterType === FilterType.CONFLICTING_VALUES,
    },
    onSuccess: data =>
      data.map(distinctValue => ({
        id: distinctValue[conflictingValuesObj.fieldName!],
        title: distinctValue[conflictingValuesObj.fieldName!],
        value: distinctValue[conflictingValuesObj.fieldName!],
      })),
  });

  const onChangeConflictingValues = (key: string, val: string | number) => {
    const newFilter = getConflictingValuesFilter({ ...conflictingValuesObj, [key]: val });
    setNewData(setNestedValue(`clientConfig.policyScenario`, newData, newFilter));
  };

  const fieldNameOptions = useMemo(
    () => aggProjs[projName].fields.map(({ id, ingressValue, title }) => ({ id, title, value: ingressValue })),
    [projName]
  );
  return (
    <Box>
      <Box sx={{ ...flex.justifyBetweenCenter, width: 843 }}>
        {cmdbObject.filterType !== FilterType.ADVANCED ? (
          <Box width={292}>
            <Tabs
              value={cmdbObject.filterType}
              onChange={(e, val) => {
                setNewData(setNestedValue('clientConfig.policyScenario', newData, cmdbSimpleFilterDefaults[val]));
              }}>
              {[
                { value: FilterType.MISSING_FIELD, label: 'Missing Field' },
                { value: FilterType.CONFLICTING_VALUES, label: 'Conflicting Values' },
              ].map(({ value, label }) => (
                <Tab key={value} value={value} label={label} />
              ))}
            </Tabs>
          </Box>
        ) : (
          'Advanced Filter'
        )}
        <PoliciesToggleButton setNewData={setNewData} newData={newData} />
      </Box>

      {cmdbObject.filterType === FilterType.MISSING_FIELD && (
        <Box sx={{ mt: 1 }}>
          <NewExpressionBuilder
            fields={filterOptions}
            isVertical={false}
            canDeleteLast
            setFilter={(filter: Filter) => setNewData(setNestedValue('clientConfig.policyScenario.filter', newData, filter))}
            filter={cmdbObject.filter}
          />
        </Box>
      )}
      {cmdbObject.filterType === FilterType.CONFLICTING_VALUES && (
        <Box sx={{ borderLeft: `2px solid ${palette.colors.primary[500]}` }}>
          <Box sx={{ ...boxSx }}>
            <Box>
              <Select
                options={fieldNameOptions}
                value={cmdbObject.fieldName}
                onChange={val => onChangeConflictingValues('fieldName', val)}
                {...selectCommonProps({ width: 210 })}
              />
            </Box>
            has more than
            <Box>
              <TextInput
                type="number"
                size="small"
                sx={{ width: 87 }}
                value={cmdbObject.uniqueValues}
                onChange={val => onChangeConflictingValues('uniqueValues', val)}
              />
            </Box>
            unique values
          </Box>
          <Box sx={{ ...boxSx }}>
            Excluding the following values
            <Select
              options={distinctOptions}
              value={cmdbObject.excludedValues}
              onChange={val => onChangeConflictingValues('excludedValues', val)}
              isMultiple
              {...selectCommonProps({ width: 267 })}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default CmdbSimpleFilter;
