import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import * as Auth from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import envVariables from '../shared/projectEnvVariables';
import { ErrorTypes } from '../types/query.types';
import {
  fetchSession,
  getAccountTokenFromLS,
  logoutFromAvalor,
  overrideAccountIdUserAttribute,
  removeCognitoLS,
  setDDUser,
  sharedWorkerAuthFlow,
  shouldSkipUILogin,
  signIn,
  updateTokenInLS,
} from '../utils/auth.utils';
import { isExportMode } from '../utils/exportDashboards.utils';
import { AuthStorageKeys, getAccessTokenTimeout, setAccountTokenInLs } from '../utils/localStorageAuthUtils.utils';
import { getBranchPrefix, useAccountId } from '../utils/router.utils';
import { shouldRecordSession } from '../utils/rum.utils';
import { convertFromSecondsToMilliseconds, noop } from '../utils/Utils';
import { sharedWorkerService } from '../workers/sharedWorkerService';

type AuthStateType = {
  user: { userName: string; email: string; userId?: string; permissions?: string; abac?: string; accountId?: string };
  isAuthenticated: boolean;
};

const authContext = createContext<Partial<AuthContextType>>({});

// eslint-disable-next-line react/prop-types
export function ProvideAuth({ children, isCurrentlyLoggingIn }) {
  const auth = useProvideAuth(isCurrentlyLoggingIn);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);
const useProvideAuth = (isCurrentlyLoggingIn: boolean = false) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(!shouldSkipUILogin());
  const [params] = useSearchParams();
  const location = useLocation();

  const pathAccountId = useAccountId();

  const [authState, setAuthState] = useState<AuthStateType>({
    user: { userName: '', email: '' },
    isAuthenticated: shouldSkipUILogin(),
  });
  const localAuth = envVariables.VITE_ENDPOINT_FOR_TOKEN;

  const useLocalAccessToken = () =>
    useMutation<string, Error, { accountId: string; email: string }>({
      mutationKey: ['local-token'],
      mutationFn: ({ accountId, email }) =>
        fetch(`${localAuth}?email=${email}&accountId=${accountId}`).then(async res => {
          const result = await res.text();
          if (res.ok) {
            return result;
          }
          throw new Error('No access Token received');
        }),
    });
  const { mutateAsync: getLocalToken } = useLocalAccessToken();

  const isTokenExpired = (jwtToken: string) => {
    const exp = Auth.decodeJWT(jwtToken)?.payload?.exp;
    return exp && convertFromSecondsToMilliseconds(exp) - 30000 < Date.now();
  };

  const onFirstLoad = async (retry: boolean = false) => {
    const hasCodeInTheUrl = params.get('code');
    if (isCurrentlyLoggingIn) {
      return;
    }
    try {
      // throws an error if the user is not authenticated
      await Auth.getCurrentUser();

      // get the token from the session, if the token is empty set isAuthenticated to false and isLoading to false => logout from app.tsx
      await getAccountToken(pathAccountId, true);

      setIsLoading(false);
    } catch (error) {
      console.error(`${ErrorTypes.Authentication}: No user is found. retry ${retry}: ${error}`);
      if (localAuth && !retry) {
        navigate('/');
        window.location.reload();
        return;
      }
      setAuthStateFromToken(null);
      if (!hasCodeInTheUrl) {
        if (retry) {
          // if after the retry there is still no token, refresh the token
          const res = await fetchSession({ firstLogin: true });
          if (res.tokens?.idToken) {
            await handleAuthSuccess(res.tokens.idToken);
          } else {
            // logout the user if there is no token after retry & refresh
            setIsLoading(false);
            console.error(`${ErrorTypes.Authentication}: No Token after retry: ${JSON.stringify(error)}`);
            logoutFromAvalor({ logout: removeCognitoLS, loginWithRedirect: noop, redirectBack: false });
            window.location.reload();
          }
        } else {
          // if the user doesn't have code in the url it might mean we have some race condition and the user is
          // in the middle of a login process. try again to get the token with setTimeout to support the e2e user
          setTimeout(() => (sharedWorkerAuthFlow() ? onFirstLoad(true) : getUser(true)), 2000);
        }
      }
      // if there is code param, lets wait for Hub.listen to get the signInWithRedirect event
    }
  };

  const parseJwt = token => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch {
      return null;
    }
  };

  const sendTokenRequest = async (isInitialLoad: boolean, accountId: string) => {
    if (isInitialLoad) {
      return fetchSession({ forceRefresh: false, firstLogin: true });
    }

    if (localAuth) {
      const token = await getLocalToken({ email: authState.user.email, accountId });
      const parsedToken = parseJwt(token);
      const tokenAccountId = parsedToken.account_id;
      if (!accountId) {
        navigate(tokenAccountId);
      }

      return { tokens: { idToken: { payload: parsedToken, toString: () => token } } };
    }

    await overrideAccountIdUserAttribute(accountId, { logout: signOut, loginWithRedirect: signIn });
    return fetchSession({});
  };

  const getTokenForAccountV2 = async (accountId: string, attempts: number, isInitialLoad: boolean = false) => {
    if (shouldSkipUILogin()) {
      const token = getAccountTokenFromLS(accountId);
      return { payload: parseJwt(token) };
    }
    const kb = location.pathname.includes('/zendesk/token');
    if (getBranchPrefix() || kb) {
      const { token, res } = await getTokenWhenNoAccount();
      const accountId = res.tokens?.idToken?.payload.account_id;
      if (accountId && !kb) {
        if (getBranchPrefix()) {
          localStorage.setItem(AuthStorageKeys.accountId, accountId.toString());
        } else {
          navigate(accountId as string);
        }
      }

      return token;
    }

    const res = await sendTokenRequest(isInitialLoad, accountId);

    const idToken = res.tokens?.idToken;

    if (idToken && (idToken.payload.account_id === accountId || !accountId)) {
      return idToken;
    }
    if (attempts < 1) {
      return getTokenForAccountV2(accountId, attempts + 1, false);
    }
    // This mostly happen to the e2e user, when the account is not the requested account but not the default account
    if (idToken?.payload.account_id !== res.tokens?.idToken?.payload.default_account_id) {
      if (attempts > 3) {
        console.error(
          `${ErrorTypes.Authentication}: attempt ${attempts} token is not valid for account ${accountId} or the default account, token account ${idToken?.payload.account_id}`
        );
      }
      return getTokenForAccountV2(accountId, attempts + 1, false);
    }
    if ((res as any)?.error) {
      if (attempts > 1) {
        console.error(`${ErrorTypes.Authentication}: attempt ${attempts} for getting token with error ${(res as any)?.error}`);
        return null;
      }
      return getTokenForAccountV2(accountId, attempts + 1, false);
    }
    if (!res.tokens?.idToken) {
      console.error(`${ErrorTypes.Authentication}: token received empty for account ${accountId} response: ${JSON.stringify(res)}`);
      window.location.reload();
      return null;
    }
    console.debug('switch for default account for user', idToken?.payload.avalor_user_id);
    const defaultAccount = idToken?.payload.default_account_id;
    enqueueSnackbar('No Permissions To Requested Account', { variant: 'error' });
    await overrideAccountIdUserAttribute(defaultAccount, { logout: signOut, loginWithRedirect: signIn });
    navigate(defaultAccount as string);
    return idToken;
  };

  const getUser = async (retry: boolean = false) => {
    const hasCodeInTheUrl = params.get('code');
    if (isCurrentlyLoggingIn) {
      return;
    }
    try {
      // throws an error if the user is not authenticated
      await Auth.getCurrentUser();
      // get the token from the session, if the token is empty set isAuthenticated to false and isLoading to false => logout from app.tsx
      const { idToken } = (await fetchSession({ forceRefresh: false, firstLogin: true })).tokens ?? {};
      setAuthState({
        user: idToken ? extractUserPropertiesFromToken(idToken.payload as TokenUser) : { userName: '', email: '' },
        isAuthenticated: !!idToken,
      });
      setIsLoading(false);
    } catch (error) {
      console.error(`${ErrorTypes.Authentication}: No user is found. retry ${retry}: ${error}`);
      setAuthState({ user: { userName: '', email: '' }, isAuthenticated: false });
      if (!hasCodeInTheUrl) {
        if (retry) {
          // if after the retry there is still no token, refresh the token
          const res = await fetchSession({ firstLogin: true });
          if (res.tokens?.idToken) {
            setAuthState({
              user: extractUserPropertiesFromToken(res.tokens.idToken.payload as TokenUser),
              isAuthenticated: true,
            });
            setIsLoading(false);
          } else {
            // logout the user if there is no token after retry & refresh
            setIsLoading(false);
            console.error(`${ErrorTypes.Authentication}: No Token after retry: ${JSON.stringify(error)}`);
            logoutFromAvalor({ logout: removeCognitoLS, loginWithRedirect: noop, redirectBack: false });
            window.location.reload();
          }
        } else {
          // if the user doesn't have code in the url it might mean we have some race condition and the user is
          // in the middle of a login process. try again to get the token with setTimeout to support the e2e user
          setTimeout(() => getUser(true), 2000);
        }
      }
      // if there is code param, lets wait for Hub.listen to get the signInWithRedirect event
    }
  };

  const setAuthStateFromToken = token => {
    if (token) {
      const formattedUserMetadata = extractUserPropertiesFromToken(token.payload);
      setAuthState({ user: formattedUserMetadata, isAuthenticated: !!token });
      if (shouldRecordSession && !isExportMode()) {
        setDDUser({ ...formattedUserMetadata, role: token.role_id });
      }
    } else {
      setAuthState({ user: { userName: '', email: '' }, isAuthenticated: !!token });
    }

    setIsLoading(false);
  };

  const extractUserPropertiesFromToken = (tokenPayload: TokenUser) => ({
    email: tokenPayload.email as string,
    userId: tokenPayload.avalor_user_id as string,
    roleId: tokenPayload.user_role_id as string,
    accountId: tokenPayload.account_id as string,
    abac: tokenPayload.abac_filters as string,
    permissions: tokenPayload.scope as string,
    userName: tokenPayload.avalor_user_name as string,
    roleName: tokenPayload.role_name as string,
  });

  useEffect(() => {
    if (sharedWorkerAuthFlow()) {
      const handleWorkerMessage = async data => {
        if (data.action === 'updateUserAuthState') {
          const token = await getTokenForAccountV2(data.accountId, 0);
          await handleAuthSuccess(token);
          sharedWorkerService.notifyWorkerAboutTokenRefresh(data.accountId);
          console.log('refresh token in this tab for account: ', data.accountId);
        }
      };

      sharedWorkerService.onMessage(data => handleWorkerMessage(data));

      return () => {
        window.removeEventListener('message', handleWorkerMessage);
      };
    }

    return () => {};
  }, [pathAccountId]);

  useEffect(() => {
    if (shouldSkipUILogin()) {
      return () => {};
    }
    let userTimeoutId: ReturnType<typeof setTimeout> | null = null;
    const unsubscribe = Hub.listen('auth', ({ payload }) => {
      const errorText = JSON.stringify((payload as any)?.data?.error?.message);
      const underlyingError = JSON.stringify((payload as any)?.data?.error?.underlyingError);
      switch (payload.event) {
        case 'customOAuthState':
          setTimeout(() => navigate(decodeURIComponent(payload.data)));
          break;
        case 'signInWithRedirect':
          // Cancel the timeout if we hit this case
          if (userTimeoutId) {
            clearTimeout(userTimeoutId);
            userTimeoutId = null; // Reset the timeout ID
          }
          if (sharedWorkerAuthFlow()) {
            onFirstLoad();
          } else {
            getUser();
          }
          break;
        case 'signInWithRedirect_failure':
          logoutFromAvalor({ logout: removeCognitoLS, loginWithRedirect: noop, redirectBack: false });
          navigate('');
          console.error(`${ErrorTypes.Authentication}: LOGIN FAIL: ${errorText} ${underlyingError}`);
          window.location.reload();
          break;
        case 'tokenRefresh_failure':
          logoutFromAvalor({ logout: removeCognitoLS, loginWithRedirect: noop, redirectBack: false });
          console.error(`${ErrorTypes.Authentication}: REFRESH TOKEN FAIL: ${errorText} ${underlyingError}`);
          window.location.reload();
          break;
        default:
          break;
      }
    });

    // Set the timeout for getUser
    userTimeoutId = setTimeout(() => {
      if (params.get('code')) {
        console.error(`${ErrorTypes.Authentication}: activate getUser after setTimeout`);
      }
      return sharedWorkerAuthFlow() ? onFirstLoad() : getUser();
    }, 1000);

    return () => {
      unsubscribe();
      if (sharedWorkerAuthFlow()) {
        sharedWorkerService.terminate();
        window.removeEventListener('beforeunload', () => {
          sharedWorkerService.notifyWorkerOnUnloadEvent(pathAccountId);
        });
      }
    };
  }, []);

  const handleAuthSuccess = async decodedToken => {
    const accountFromToken = decodedToken.payload.account_id;
    if (!pathAccountId) {
      navigate(accountFromToken);
    } else {
      setAccountTokenInLs({ accountId: accountFromToken, token: decodedToken.toString() });
    }
    setAuthStateFromToken(decodedToken);
  };

  const updateUserAuthState = token => {
    const formattedUserMetadata = extractUserPropertiesFromToken(token);
    setAuthState({ user: formattedUserMetadata, isAuthenticated: !!token });
    if (shouldRecordSession && !isExportMode()) {
      setDDUser({ ...formattedUserMetadata, role: token.role_id });
    }
  };

  const updateTokenForUser = async (accountId, attempts = 0) => {
    if (localAuth) {
      const token = await getLocalToken({ email: authState.user.email, accountId });
      const parsedToken = parseJwt(token);
      const tokenAccountId = parsedToken.account_id;
      if (!accountId) {
        navigate(tokenAccountId);
      }
      updateTokenInLS({ accountId: tokenAccountId, token });
      updateUserAuthState(parsedToken);
      return token;
    }
    if (shouldSkipUILogin()) {
      const token = getAccountTokenFromLS(accountId);
      if (token) {
        updateUserAuthState(parseJwt(token));
      }
      return token;
    }
    if (!accountId || getBranchPrefix()) {
      const kb = location.pathname.includes('/zendesk/token');
      const { token, res } = await getTokenWhenNoAccount();
      const accountId = res.tokens?.idToken?.payload.account_id;
      if (accountId && !kb) {
        if (getBranchPrefix()) {
          localStorage.setItem(AuthStorageKeys.accountId, accountId.toString());
        } else {
          navigate(accountId as string);
        }
      }

      updateTokenInLS({ accountId, token });
      updateUserAuthState(res.tokens?.idToken?.payload as TokenUser);
      return token;
    }
    // if in local storage there is a token for the account, and it is not expired for more than 4 minutes
    // return the token to remove the API calls
    const oldToken = getAccountTokenFromLS(accountId);
    const exp = oldToken ? Auth.decodeJWT(oldToken)?.payload?.exp : undefined;
    const timeout = getAccessTokenTimeout() / 1000 - 30; // a minute before the actual expiration
    if (exp && exp > Date.now() / 1000 + timeout) {
      updateUserAuthState(Auth.decodeJWT(oldToken).payload as TokenUser);
      return oldToken;
    }
    await overrideAccountIdUserAttribute(accountId, { logout: signOut, loginWithRedirect: signIn });
    const res = await fetchSession({});
    const token = getToken(res);
    if (res.tokens?.idToken?.payload.account_id === accountId && token) {
      updateTokenInLS({ accountId, token });
      updateUserAuthState(res.tokens?.idToken?.payload as TokenUser);
      return token;
    }
    if (attempts < 1) {
      console.debug(
        `${attempts + 1} attempt to get access token for the account ${accountId} has failed. recieved token for account: ${res.tokens
          ?.idToken?.payload.account_id}`,
        res.tokens?.idToken?.payload.avalor_user_id
      );
      const token = await updateTokenForUser(accountId, attempts + 1);
      return token;
    }
    if (res.tokens?.idToken?.payload.account_id !== res.tokens?.idToken?.payload.default_account_id) {
      if (attempts > 3) {
        console.error(
          `${ErrorTypes.Authentication}: attempt ${attempts} token is not valid for account ${accountId} or the default account, token account ${res.tokens?.idToken?.payload.account_id}`
        );
      }
      const newToken = await updateTokenForUser(accountId, attempts + 1);
      return newToken;
    }
    if ((res as any)?.error) {
      if (attempts > 1) {
        console.error(`${ErrorTypes.Authentication}: attempt ${attempts} for getting token with error ${(res as any)?.error}`);
        return null;
      }
      const tokenNoNetwork = await updateTokenForUser(accountId, attempts + 1);
      return tokenNoNetwork;
    }
    if (!res.tokens?.idToken) {
      console.error(`${ErrorTypes.Authentication}: token received empty for account ${accountId} response: ${JSON.stringify(res)}`);
      window.location.reload();
      return null;
    }
    console.debug('switch for default account for user', res.tokens?.idToken?.payload.avalor_user_id);
    const defaultAccount = res.tokens?.idToken?.payload.default_account_id;
    enqueueSnackbar('No Permissions To Requested Account', { variant: 'error' });
    await overrideAccountIdUserAttribute(defaultAccount, { logout: signOut, loginWithRedirect: signIn });
    updateTokenInLS({ accountId: defaultAccount, token });
    updateUserAuthState(res.tokens?.idToken?.payload as TokenUser);
    navigate(defaultAccount as string);
    return token;
  };

  const getAccountToken = async (accountId: string, isInitialLoad = false) => {
    const accountToken = getAccountTokenFromLS(accountId);
    const shouldRefetch = !accountToken || isTokenExpired(accountToken);
    // on switch account
    if (shouldRefetch) {
      const token = await getTokenForAccountV2(accountId, 0, isInitialLoad);
      await handleAuthSuccess(token);
      sharedWorkerService.notifyWorkerAboutTokenRefresh(pathAccountId);
    } else {
      await handleAuthSuccess(Auth.decodeJWT(accountToken));
    }

    return getAccountTokenFromLS(accountId);
  };

  const getToken = res => {
    const token = res.tokens?.idToken;
    if (token) {
      return token.toString();
    }
    if ((res as any)?.error) {
      console.error(`${ErrorTypes.Authentication}: No Token received`, JSON.stringify(token));
    }
    return undefined;
  };

  const getTokenWhenNoAccount = async () => {
    const res = await fetchSession({ forceRefresh: false });
    const token = getToken(res);
    if (token) {
      return { token, res };
    }
    const refreshRes = await fetchSession({});
    return { token: getToken(refreshRes), res: refreshRes };
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      setAuthState({ user: { userName: '', email: '' }, isAuthenticated: false });
      setIsLoading(true);
      return { success: true, message: '' };
    } catch {
      return {
        success: false,
        message: 'LOGOUT FAIL',
      };
    }
  };

  const getAccessToken = accountId => (sharedWorkerAuthFlow() ? getAccountToken(accountId) : updateTokenForUser(accountId));

  return {
    isLoading,
    ...authState,
    getAccessToken,
    signIn,
    signOut,
  };
};

type AuthContextType = {
  isLoading: boolean;
  isAuthenticated: boolean;
  user: User;
  getAccessToken: (accountId: string) => Promise<string>;
  signIn: (state?) => void;
  signOut: () => Promise<AuthResponse>;
};

type User = { userName: string; email: string; userId?: string; permissions?: string; abac?: string; accountId?: string };

type TokenUser = {
  email: string;
  avalor_user_id: string;
  user_role_id: string;
  account_id: string;
  abac_filters: string;
  scope: string;
  avalor_user_name: string;
  role_name: string;
};

type AuthResponse = {
  success: boolean;
  message: string;
};
