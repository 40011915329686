import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { useAvContext } from '../context/AvContextProvider';
import { AppDto } from '../context/types';

export const GET_ALL_APPS = gql`
  query {
    findAllApps {
      id
      name
    }
  }
`;

export const useAllApps = () => {
  const { api } = useAvContext();
  return useQuery<AppDto[]>({
    queryKey: ['findAllApps'],
    queryFn: () => api(GET_ALL_APPS).then(data => data?.data?.findAllApps),
  });
};
