export const clickthroughPrefix = 'clickthrough_';
export const clickthroughWidgetId = `${clickthroughPrefix}widgetId`;
export const clickthroughDimsPrefix = `${clickthroughPrefix}dim_`;
export const clickthroughMetricsPrefix = `${clickthroughPrefix}metric`;
export const clickthroughOrderBy = `${clickthroughPrefix}orderBy`;
export const clickthroughTablePage = `${clickthroughPrefix}tablePage`;
export const clickthroughHeadCells = `${clickthroughPrefix}headCells`;
export const clickthroughNotOthersDimsPrefix = `${clickthroughPrefix}not_others_dim_`;
export const defaultNumberColumns = 5;
export const rowLimit = 25;
