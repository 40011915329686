import { useLocation } from 'react-router-dom';
import { AuthStorageKeys } from './localStorageAuthUtils.utils';

export const getAccountIdFromPathName = pathname => pathname?.split?.('/')?.[pathname.indexOf('AVA-') > -1 ? 2 : 1];
export const getAppIdFromPathName = pathname => pathname?.split?.('/')?.[pathname.indexOf('AVA-') > -1 ? 3 : 2];

export const getBranchPrefix = () => {
  const regex = /\/AVA-(\d+)\//;
  const match = window.location.pathname.match(regex);
  if (match) {
    return `AVA-${match[1]}`;
  }
  return '';
};

export const getPathNameByAccount =
  (accountId, selectedApp: string) =>
  (path, rest = '', newSelectedApp = selectedApp) =>
    `${getBranchPrefix()}/${accountId}/${newSelectedApp}/${path}${rest}`;

export const useAccountId = () => {
  const location = useLocation();
  const id = getBranchPrefix() ? localStorage.getItem(AuthStorageKeys.accountId) : getAccountIdFromPathName(location.pathname);
  return id === 'error-process-request' ? '' : id;
};
