import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { dotStyle, stringToColor } from '../utils/Utils';
import { flex } from './AvThemeProvider';

interface AvAvatarProps {
  displayName?: string;
}

const AvAvatar: React.FC<AvAvatarProps> = ({ displayName = 'Unknown' }) => {
  const theme = useTheme();
  return (
    /* @ts-ignore */
    <Box
      component="span"
      sx={{
        ...flex.center,
        backgroundColor: stringToColor(displayName, theme.palette.avatarColors),
        ...dotStyle(undefined, '100%' as any),
      }}>
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: 12,
          color: theme.palette.white.main,
          textTransform: 'uppercase',
        }}>
        {displayName[0]}
      </Typography>
    </Box>
  );
};
export default AvAvatar;
