import React, { ReactNode } from 'react';
import { alpha, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useAvContext } from '../context/AvContextProvider';
import { rebranding } from '../rebranding';
import { SEVERITY_LABELS, SeverityIcons } from '../utils/severity.utils';
import { ellipsis, isNullOrUndefined } from '../utils/Utils';
import AvAvatar from './AvAvatar';
import { flex } from './AvThemeProvider';
import AvTooltip from './AvTooltip';

interface LogoProps {
  type?: string;
  width?: number;
  children?: ReactNode;
  transparent?: boolean;
  title?: string;
  iconUrl?: string;
  style?: object;
}

export function Logo({ type, width = 24, children, transparent = false, title, iconUrl, style = {} }: LogoProps) {
  const sx = {
    ...flex.center,
    width,
    aspectRatio: '1 / 1',
    backgroundColor: transparent ? 'inherit' : theme => theme.palette.colors.neutrals[300],
    borderRadius: '100%',
    flexShrink: 0,
    '> img': {
      objectFit: 'contain',
      width: width * 0.7,
      height: width * 0.7,
    },
    ...style,
  };
  const tempIconUrl = iconUrl?.replace('logos', 'logos2');

  const onError = ({ target }) => {
    // eslint-disable-next-line no-param-reassign
    target.onerror = null;
    // eslint-disable-next-line no-param-reassign
    target.src = rebranding
      ? new URL('../assets/logos2/GENERIC_UPLOAD.webp', import.meta.url).href
      : new URL('../assets/logos/GENERIC_UPLOAD.webp', import.meta.url).href;
  };

  const imageUrl = new URL(`/src/assets/logos${rebranding ? '2' : ''}/${type || 'GENERIC_UPLOAD'}.webp`, import.meta.url).href;
  const iconImageURL = new URL(`/src/assets/${tempIconUrl}`, import.meta.url).href;
  const logo = <Box sx={sx}>{children || <img src={tempIconUrl ? iconImageURL : imageUrl} onError={onError} alt={type} />}</Box>;
  return title ? <AvTooltip title={title}>{logo}</AvTooltip> : logo;
}

export const logoStyle = (size, neutralColor = false) => ({
  width: size,
  height: size,
  color: theme => (neutralColor ? theme.palette.colors.neutrals[500] : theme.palette.colors.primary[500]),
  backgroundColor: theme => theme.palette.colors.neutrals[300],
  borderRadius: '50%',
});

interface ItemWithLogoProps {
  variant: 'destinationsMap' | 'sourcesMap' | 'sourcesMapByName' | 'authTypesMap';
  type: string;
  logoWidth?: number;
  textStyle?: object;
  hideText?: boolean;
  tooltip?: boolean;
  isAvatar?: boolean;
}

export function ItemWithLogo({
  variant,
  type,
  logoWidth = 24,
  textStyle = {},
  hideText = false,
  tooltip = true,
  isAvatar = false,
}: ItemWithLogoProps) {
  const { typeNameMap } = useAvContext();
  const logoProps = isAvatar
    ? {}
    : variant === 'destinationsMap'
      ? typeNameMap.destinationsObj[variant][type]
      : variant === 'authTypesMap'
        ? typeNameMap.authenticationSourcesObj[variant][type]
        : typeNameMap.sourcesObj[variant][type];
  const displayName = logoProps?.name || type || 'Unknown';
  const content = (
    <Box sx={{ ...flex.row, gap: 1, lineHeight: `${logoWidth}px`, ...ellipsis, flexShrink: isAvatar ? 0 : undefined }}>
      {isAvatar ? (
        <Logo {...logoProps} width={logoWidth} transparent>
          <AvAvatar displayName={displayName} />
        </Logo>
      ) : (
        <Logo {...logoProps} width={logoWidth} />
      )}
      {!hideText && (
        <AvTooltip>
          <Box component="span" sx={textStyle}>
            {displayName}
          </Box>
        </AvTooltip>
      )}
    </Box>
  );
  return tooltip && hideText ? <AvTooltip title={displayName}>{content}</AvTooltip> : content;
}

interface SeverityWithIconProps {
  color: string;
  variant: 'contained' | 'minimal';
  width: number;
  hoverTransition: boolean;
}

const paddingX = 4;
const severityWithIconStyle = ({ color, variant, width, hoverTransition }: SeverityWithIconProps) => ({
  ...flex.itemsCenter,
  ...(variant === 'contained'
    ? {
        gap: '4px',
        padding: `3px ${paddingX}px`,
        backgroundColor: theme => (rebranding ? alpha(theme.palette[color].main, 0.15) : `${theme.palette[color].main}14`),
        fontWeight: 600,
        borderRadius: '8px',
        fontSize: 12,
        '& > div': {
          display: 'flex',
          '& > svg': {
            width: width * 0.7,
            height: width * 0.7,
          },
        },
        ...(hoverTransition
          ? {
              width: width * 0.7 + paddingX * 2,
              overflow: 'hidden',
              transition: theme => theme.transitions.create(['width'], { duration: theme.transitions.duration.short }),
              '&:hover': { width: '100%' },
              '> *': {
                flexShrink: 0,
                transition: theme => theme.transitions.create(['opacity'], { duration: theme.transitions.duration.short }),
              },
              '&:not(:hover) > div ~ *': { opacity: 0 },
            }
          : {}),
      }
    : {
        gap: '8px',
        '& > div': {
          ...flex.center,
          width,
          height: width,
          borderRadius: `${width * 0.2}px`,
          color: theme => theme.palette[color].main,
          backgroundColor: theme =>
            rebranding
              ? alpha(theme.palette[color].main, ['warning', 'low'].includes(color) ? 0.15 : 0.1)
              : `${theme.palette[color].main}14`,
          '& > svg': {
            width: width * 0.7,
            height: width * 0.7,
          },
        },
      }),
});

interface SeverityItemProps {
  value: string;
  showText?: boolean;
  numericValue?: number;
  variant?: 'contained' | 'minimal';
  width?: number;
  hoverTransition?: boolean;
}

export function SeverityItem({
  value,
  variant = 'minimal',
  width = 24,
  showText = true,
  numericValue,
  hoverTransition = false,
}: SeverityItemProps) {
  const severityIcon = SeverityIcons[value?.toUpperCase?.()];
  if (!severityIcon) {
    return value;
  }
  return (
    <Box sx={severityWithIconStyle({ color: severityIcon.color, variant, width, hoverTransition })}>
      <Box sx={{ flexShrink: 0 }}>{severityIcon.icon}</Box>
      {!isNullOrUndefined(numericValue) && <span style={{ whiteSpace: 'nowrap', fontWeight: 600 }}>{numericValue?.toFixed(1)}</span>}
      {showText && <span style={ellipsis}>{SEVERITY_LABELS[value]}</span>}
    </Box>
  );
}

interface StatusWithLogoProps {
  statusText: string;
  icon: ReactNode;
  width?: number;
  hideTextOnSmallScreens?: boolean;
}

export function StatusWithLogo({ statusText, icon, width = 22, hideTextOnSmallScreens = true }: StatusWithLogoProps) {
  const sx = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    svg: {
      width,
      height: width,
      color: theme => theme.palette.colors.neutrals[600],
    },
    width,
    height: width,
    borderRadius: 100,
    flexShrink: 0,
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('laptop'));
  const hideText = isSmallScreen && hideTextOnSmallScreens;
  return (
    <AvTooltip title={hideText ? statusText : ''}>
      <div style={{ display: 'flex', gap: '8px', lineHeight: `${width}px` }}>
        <Box sx={sx}>{icon}</Box>
        {!hideText && <span>{statusText}</span>}
      </div>
    </AvTooltip>
  );
}
