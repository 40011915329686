import { ProjectionID, SortBy, TopResults } from '../views/CustomDashboards/types';
import { Filter } from './filter.types';

export interface QueryObjectProto {
  dims: Dim[];
  metrics: Metric[];
  scripts?: FieldScript[];
  filter?: Filter;
  groupBy?: string[];
  sorting?: SortBy[];
  top?: TopResults;
  sourceProjection: ProjectionID;
}

export interface Dim {
  fieldPath?: string;
  alias?: string;
}

export interface Metric {
  name?: string;
  agg: FieldFilterAndAgg;
}

export type FieldFilterAndAgg = {
  aggName: string;
  args: { stringValue }[];
  function: string;
  filter?: Filter;
};

export type FieldScript = {
  script: string;
  aggNames?: string[];
  evalStrategy: Strategy;
};

export enum Strategy {
  CEL = 'CEL',
  PYTHON_SCRIPT_PER_FIELD = 'PYTHON_SCRIPT_PER_FIELD',
}
