import React, { useState } from 'react';
import { Box, Divider, InputLabel, Slider, Typography } from '@mui/material';
import { rebranding } from '../rebranding';
import { SEVERITY_LABELS, severityBackgroundColorOrUndefined, SeverityIcons, SeverityLabels } from '../utils/severity.utils';
import { flex } from './AvThemeProvider';
import TextInput from './TextInput';

const marks = [
  { value: 0.1, label: '0.1' },
  { value: 2, label: '2' },
  { value: 4, label: '4' },
  { value: 6, label: '6' },
  { value: 8, label: '8' },
  { value: 9.9, label: '10' },
];

const severityConfig: { label: string; upperThreshold?: number }[] = [
  { label: 'INFO', upperThreshold: 2 },
  { label: 'LOW', upperThreshold: 4 },
  { label: 'MEDIUM', upperThreshold: 6 },
  { label: 'HIGH', upperThreshold: 8 },
  { label: 'CRITICAL' },
];

const calculateSeverity = (value: number) =>
  severityConfig.find(cur => (cur.upperThreshold ? value <= cur.upperThreshold : true))?.label || 'CRITICAL';

type AvSeveritySliderProps = {
  value: number;
  onChange: (severity_score: number, severity: string) => void;
};

const AvSeveritySlider = ({ value, onChange }: AvSeveritySliderProps) => {
  const [error, setError] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);

  const handleSliderChange = newValue => onChange(newValue, calculateSeverity(newValue).toUpperCase());

  const handleInputChange = num => {
    if (num > 10 || num < 0) {
      setError('Number should be between 0 and 10');
    } else {
      onChange(num, calculateSeverity(num));
      setError('');
    }
  };
  return (
    <div>
      <InputLabel required>Severity</InputLabel>
      <Box sx={{ ...flex.itemsCenter, gap: '20px' }}>
        <Slider
          sx={{
            '.MuiSlider-rail': rebranding
              ? {
                  background: theme => `linear-gradient(90deg,
                 ${severityBackgroundColorOrUndefined(SeverityLabels.Info, theme)} 0%, ${severityBackgroundColorOrUndefined(
                   SeverityLabels.Info,
                   theme
                 )} 20%,
                 ${severityBackgroundColorOrUndefined(SeverityLabels.Low, theme)} 20%, ${severityBackgroundColorOrUndefined(
                   SeverityLabels.Low,
                   theme
                 )} 40%, ${severityBackgroundColorOrUndefined(SeverityLabels.Medium, theme)} 40%,
                 ${severityBackgroundColorOrUndefined(SeverityLabels.Medium, theme)} 60%, ${severityBackgroundColorOrUndefined(
                   SeverityLabels.High,
                   theme
                 )} 60%, ${severityBackgroundColorOrUndefined(SeverityLabels.High, theme)} 80%, 
                 ${severityBackgroundColorOrUndefined(SeverityLabels.Critical, theme)} 80%, ${severityBackgroundColorOrUndefined(
                   SeverityLabels.Critical,
                   theme
                 )} 100% )`,
                  opacity: '1',
                }
              : {
                  background: ({
                    palette: {
                      colors: { neutrals, negative, yellow, orange, blue },
                    },
                  }) => `linear-gradient(90deg,
                 ${neutrals[300]} 0%, ${neutrals[300]} 20%,
                 ${blue[500]} 20%, ${blue[500]} 40%, ${yellow[500]} 40%,
                 ${yellow[500]} 60%, ${orange[500]} 60%, ${orange[500]} 80%, 
                 ${negative[500]} 80%, ${negative[500]} 100% )`,
                  opacity: '1',
                },
            '.MuiSlider-track': {
              backgroundColor: 'transparent',
              borderWidth: 0,
            },
            '.MuiSlider-mark': {
              backgroundColor: 'transparent',
            },
            '.MuiSlider-thumb': {
              border: theme => `2px solid ${theme.palette.colors.neutrals[800]}`,
              backgroundColor: theme => theme.palette.white.main,
            },
            '.MuiSlider-markLabel': {
              fontSize: 12,
              lineHeight: 1.5,
            },

            height: 6,
            width: 390,
            marginTop: '2px',
          }}
          value={value}
          onChange={(e, value) => handleSliderChange(value)}
          min={0}
          max={10}
          step={0.1}
          marks={marks}
          valueLabelDisplay="auto"
        />
        <TextInput
          value={isFocused ? value : value === null ? '0.0' : value.toFixed(1)}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          variant="outlined"
          size="small"
          sx={{
            width: 150,
            mb: '18px',
            '& .MuiInputBase-input': {
              lineHeight: 1,
              fontWeight: 600,
            },
            '.MuiOutlinedInput-root.MuiInputBase-root': {
              pr: 1,
              pl: '10px',
            },
          }}
          type="number"
          error={error}
          inputProps={{
            endAdornment: (
              <Box sx={{ width: 100, ...flex.itemsCenter }}>
                <Divider orientation="vertical" flexItem sx={{ height: 20, marginTop: '3px' }} />
                <Box sx={{ width: 94, pl: 1, ...flex.itemsCenter, gap: '4px' }}>
                  {SeverityIcons[calculateSeverity(value).toUpperCase()].icon}
                  <Typography variant="body2">{SEVERITY_LABELS[calculateSeverity(value)]} </Typography>
                </Box>
              </Box>
            ),
          }}
        />
      </Box>
    </div>
  );
};

export default AvSeveritySlider;
