import React, { useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import AvAssignmentInput from '../../components/AvAssignmentInput';
import AvForm from '../../components/AvForm';
import AvFormCard from '../../components/AvFormCard';
import AvSeveritySlider from '../../components/AvSeveritySlider';
import { flex } from '../../components/AvThemeProvider';
import NewExpressionBuilder from '../../components/filters/NewExpressionBuilder';
import SchedulerSetting from '../../components/Scheduler/SchedulerSetting';
import { CustomFrequencyTypesNames } from '../../components/Scheduler/Utils';
import Select from '../../components/Select';
import SwitchToggle from '../../components/SwitchToggle';
import TextInput from '../../components/TextInput';
import { useAvContext } from '../../context/AvContextProvider';
import useDebounce from '../../hooks/useDebounce';
import { PAGE_PATHS } from '../../types';
import {
  ExecutableUnit,
  ExecutionConfig,
  FilterType,
  PolicyCategories,
  PolicyCategory,
  PolicyCategoryId,
  PolicyType,
} from '../../types/executionRules.types';
import { Filter } from '../../types/filter.types';
import { QueryObjectProto } from '../../types/QueryObjectProto.types';
import { SEVERITY_LABELS, SeverityIcons } from '../../utils/severity.utils';
import { formatCronValue, setNestedValue } from '../../utils/Utils';
import { parseJsonOrJsonl } from '../FactorRules/components/utils';
import { PreviewDrawer } from '../Sources/Mapping/PreviewPoppers';
import EditPolicyDialog from './components/EditPolicyDialog';
import PoliciesToggleButton from './components/PoliciesToggleButton';
import { useGetPolicyById, useGetSimpleComponent, useTestPolicy, useUpdatePolicy } from './hooks';
import { categoryToDefaultFilterMap, defaultPolicyTypeProps } from './utils';
import { ReactComponent as Table } from '../../assets/Table.svg';

const textInputWidth = { sx: { width: '554px' } };

// TODO AVA-11613
const fields = [
  { name: 'title', displayName: 'Title', type: 'string' },
  { name: 'description', displayName: 'Description', type: 'string' },
];

export enum EditPageModes {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DUPLICATE = 'DUPLICATE',
}

const isExecutionConfig = (unit?: ExecutableUnit): unit is ExecutableUnit & ExecutionConfig =>
  (unit as ExecutionConfig).executionConfig !== undefined;

const EditPolicy = ({ mode }: { mode: string }) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const {
    accountEntities: { aggProjs },
    userPermissions: { hasAllowedEditResourcePermission },
    getPathName,
  } = useAvContext();

  const [newData, setNewData] = useState<PolicyType>(defaultPolicyTypeProps);
  const navigate = useNavigate();

  const unit = newData.executableUnits[0];
  const projection = (unit.primaryQuery.query as { queryObject: QueryObjectProto }).queryObject.sourceProjection.name;
  const cron = newData.executionRunConfiguration.trigger.value;
  const { filterType } = newData.clientConfig.policyScenario;
  const filter = FilterType.CONFLICTING_VALUES !== filterType ? newData.clientConfig.policyScenario.filter : undefined;
  const category = newData.metadata.type;

  const [showPreview, setShowPreview] = useState(false);
  const [previewData, setPreviewData] = useState<PolicyType[] | undefined>(undefined);

  const [showEditWarningPopper, setShowEditWarningPopper] = useState<boolean>(false);

  const { mutateAsync: editPolicy } = useUpdatePolicy(mode === EditPageModes.EDIT);

  const isAllowedEdit = hasAllowedEditResourcePermission({
    path: PAGE_PATHS.POLICIES,
    isNew: !id,
  });

  useGetPolicyById({
    id,
    onSuccess: data => {
      if (mode === EditPageModes.DUPLICATE) {
        const { id: PolicyId, executionRunConfiguration, ...rest } = data;
        const { id: runConfigId, ...executionRunConfigurationWithoutId } = executionRunConfiguration;
        const dataWithoutId = { ...rest, executionRunConfiguration: executionRunConfigurationWithoutId };
        setNewData(dataWithoutId);
      } else {
        setNewData(data);
      }
    },
  });

  const { debouncedValue, isDebouncing } = useDebounce(newData, 2000);
  const { isLoading: previewLoading } = useTestPolicy({
    policy: debouncedValue,
    onSuccess: data => {
      if (!data.result) {
        setPreviewData([]);
        return;
      }
      const newPreviewData = parseJsonOrJsonl(data.result);
      setPreviewData(newPreviewData.length ? newPreviewData : [newPreviewData]);
    },
    onError: error => enqueueSnackbar(`Failed to evaluate policy violations: ${error.message}`, { variant: 'error' }),
    showPreview,
  });

  const onChange = (field: string) => value => setNewData({ ...newData, [field]: value });

  const onChangeMetadata = (field: string) => value => onChange('metadata')({ ...newData.metadata, [field]: value });

  const onChangeCron = val => setNewData(setNestedValue('executionRunConfiguration.trigger.value', newData, formatCronValue(val) || null));

  const commonMetadataProps = (fieldName: string, isRequired = false) => ({
    size: 'small',
    value: newData.metadata[fieldName],
    onChange: onChangeMetadata(fieldName),
    isRequired,
  });

  const onChangeSeverity = (severityScore: number, severity: string) => {
    setNewData({ ...newData, metadata: { ...newData.metadata, severity_score: severityScore, severity } });
  };

  const onChangeFieldValue = (value, currentEditedField, assignmentType) =>
    setNewData({
      ...newData,
      executableUnits: [
        isExecutionConfig(unit)
          ? setNestedValue('executionConfig.fields', unit, [
              ...unit.executionConfig.fields.filter(field => field.name !== currentEditedField),
              {
                name: currentEditedField,
                assignment: { assignmentType, value: value || '' },
              },
            ])
          : unit,
      ],
    });

  const onSave = onSuccess => {
    if (mode === EditPageModes.EDIT) {
      setShowEditWarningPopper(true);
    } else {
      editPolicy({
        data: newData,
        onSuccess: () => {
          enqueueSnackbar('Saved Successfully', { variant: 'success' });
          onSuccess();
        },
      });
    }
  };

  const onEditDialog = () => {
    editPolicy({
      data: newData,
      onSuccess: () => {
        enqueueSnackbar('Saved Successfully', { variant: 'success' });
        navigate(getPathName(PAGE_PATHS.POLICIES));
      },
    });
  };

  const assetPopulationFilterOptions = aggProjs[projection].fields;
  const advancedFilterOptions = aggProjs[projection].fieldList.INTERACTIVE;
  const previewHeadersFields = previewData?.[0] ? Object.keys(previewData[0]) : [];

  const categoryToComponentMap = useGetSimpleComponent({ newData, setNewData, filterOptions: advancedFilterOptions, projName: projection });

  const getFieldValue = fieldName =>
    isExecutionConfig(unit) ? unit.executionConfig.fields.find(cur => cur.name === fieldName)?.assignment : undefined;
  const onDetailsCardCloseInfo = (
    <Box sx={{ ...flex.itemsStart, gap: 2, ml: 3 }}>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        <Box sx={{ fontWeight: 600, color: theme => theme.palette.colors.neutrals[500] }}>Title:</Box>
        {newData.metadata.policy_name}
      </Box>
      <Divider orientation="vertical" flexItem sx={{ height: 20, mt: '3px' }} />
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        <Box
          sx={{ fontWeight: 600, color: theme => theme.palette.colors.neutrals[500] }}
          color={theme => theme.palette.colors.neutrals[500]}>
          Description:
        </Box>
        {newData.metadata.policy_description}
      </Box>
      <Divider orientation="vertical" flexItem sx={{ height: 20, mt: '3px' }} />
      <Box sx={{ ...flex.itemsCenter }}>
        <Box
          sx={{ fontWeight: 600, color: theme => theme.palette.colors.neutrals[500] }}
          color={theme => theme.palette.colors.neutrals[500]}>
          Severity:
        </Box>
        <Box sx={{ ml: 1 }}>
          <Box sx={{ width: 94, ...flex.itemsCenter, gap: 1 }}>
            {SeverityIcons[newData.metadata.severity.toUpperCase()].icon}
            <Box sx={{ fontWeight: 600 }}>{newData.metadata.severity_score}</Box>
            {SEVERITY_LABELS[newData.metadata.severity]}
          </Box>
        </Box>
      </Box>
    </Box>
  );
  return (
    <AvForm
      title={
        mode === EditPageModes.CREATE
          ? 'Create New Policy'
          : `${mode === EditPageModes.EDIT ? 'Edit' : 'Duplicate'} Policy ${newData.metadata.policy_name}`
      }
      saveFunc={onSave}
      isAllowedEdit={isAllowedEdit}
      navigationOnSave={getPathName(PAGE_PATHS.POLICIES)}>
      <AvFormCard title="Details" onCloseProps={onDetailsCardCloseInfo}>
        <Box
          sx={{
            ...flex.itemsCenter,
            gap: '120px',
            '& .switchToggle': {
              height: 40,
              pt: 3,
            },
          }}>
          <TextInput
            size="small"
            label="Title"
            value={newData.metadata.policy_name}
            onChange={onChangeMetadata('policy_name')}
            helperText="Valid name required"
            isRequired
            inputProps={textInputWidth}
          />
          <SwitchToggle
            label="Active"
            value={newData.executionRunConfiguration.active}
            onChange={() =>
              setNewData(prev => setNestedValue('executionRunConfiguration.active', newData, !prev.executionRunConfiguration.active))
            }
          />
        </Box>
        <TextInput label="Description" {...commonMetadataProps('policy_description')} size="small" inputProps={textInputWidth} />
        <Select
          label="Category"
          options={PolicyCategories.map(id => ({ value: id, title: PolicyCategory[id] }))}
          {...commonMetadataProps('type', true)}
          variant="input"
          style={{
            '.MuiOutlinedInput-notchedOutline, .MuiInputBase-root': {
              width: '554px',
            },
          }}
          onChange={category => {
            setNewData({
              ...newData,
              clientConfig: categoryToDefaultFilterMap[category] || defaultPolicyTypeProps.clientConfig,
              metadata: { ...newData.metadata, type: category },
            });
          }}
          value={category}
        />
        <AvSeveritySlider value={newData.metadata.severity_score} onChange={onChangeSeverity} />
      </AvFormCard>
      <AvFormCard title="Policy Criteria">
        Select the scenario this policy will detect and which assets it should be enforced for
        <Typography variant="h6" mt={2}>
          Asset Population
        </Typography>
        Enforce policy only for assets answering the following condition:
        <NewExpressionBuilder
          setFilter={(filter: Filter) => {
            setNewData(setNestedValue('clientConfig.assetPopulationFilter', newData, filter));
          }}
          fields={assetPopulationFilterOptions}
          isVertical={false}
          filter={newData.clientConfig.assetPopulationFilter}
          defaultField={assetPopulationFilterOptions[0].value}
        />
        <Box sx={{ ...flex.justifyBetweenCenter, width: 842 }}>
          <Typography variant="h6">Policy Scenario</Typography>
          {category !== PolicyCategory[PolicyCategoryId.CMDB] && category !== PolicyCategory[PolicyCategoryId.NONE] && (
            <PoliciesToggleButton setNewData={setNewData} newData={newData} />
          )}
        </Box>
        {categoryToComponentMap[newData.metadata.type]}
        {filterType === FilterType.ADVANCED && (
          <NewExpressionBuilder
            fields={advancedFilterOptions}
            isVertical={false}
            canDeleteLast
            setFilter={(filter: Filter) => setNewData(setNestedValue('clientConfig.policyScenario.filter', newData, filter))}
            filter={filter}
            showIngressToggle={filterType === FilterType.ADVANCED}
            defaultField="asset.name"
          />
        )}
      </AvFormCard>
      <AvFormCard title="Trigger Settings">
        <SchedulerSetting
          cron={cron ? `0 ${cron}` : cron}
          setCron={onChangeCron}
          customTimeTypes={[CustomFrequencyTypesNames.DAYS, CustomFrequencyTypesNames.HOURS]}
          cronOptions={{ allowNone: false, hideCustomDays: true }}
          isMultipleHours
        />
      </AvFormCard>
      <AvFormCard title="Policy Violation Findings">
        {fields.map(field => (
          <AvAssignmentInput
            key={field.name}
            fieldName={field.name}
            fieldDisplayName={field.displayName}
            assignment={getFieldValue(field.name)}
            onSaveEditorValue={onChangeFieldValue}
            projName={projection}
            fieldType={field.type}
          />
        ))}

        <Box sx={{ ...flex.itemsCenter, marginTop: '10px' }}>
          <Button variant="outlined" onClick={() => setShowPreview(true)} size="small">
            <Table /> Preview
          </Button>
        </Box>
        {showPreview && (
          <PreviewDrawer
            headers={previewHeadersFields}
            hasPreviewId={showPreview}
            onClose={() => setShowPreview(false)}
            previewRows={previewData}
            isLoading={previewLoading || isDebouncing}
            label="Policy Violations"
            nameMap={aggProjs[projection].nameMap}
          />
        )}
      </AvFormCard>
      {showEditWarningPopper && (
        <EditPolicyDialog
          onClose={isApplied => {
            if (isApplied) {
              onEditDialog();
            }
            setShowEditWarningPopper(false);
          }}
        />
      )}
    </AvForm>
  );
};

export default EditPolicy;
