import { useEffect, useRef } from 'react';
import { gql } from '@apollo/client';
import { useQuery } from '@tanstack/react-query';
import { IDisposable, languages } from 'monaco-editor';
import { useAvContext } from '../../../context/AvContextProvider';
import { orderByKey } from '../../../utils/Utils';
import { SubAutoComplete } from './types';

// eslint-disable-next-line import/prefer-default-export
export const useGroupingRulesSet = projectionName => {
  const { api } = useAvContext();
  return useQuery({
    queryKey: ['groupingRules', projectionName],
    queryFn: () =>
      api(GET_LIST, { options: { projectionName } }).then(data =>
        orderByKey(data?.data?.getRuleSets || data?.data?.getRuleSetsV2, 'name')
      ),
    gcTime: 0,
  });
};

const GET_LIST = gql`
  query getRuleSetsV2($projectionName: String!) {
    getRuleSetsV2(projectionName: $projectionName)
  }
`;

const isBetweenChars = (startChars, endChars, str, cursorPosition) => {
  let inside = false;
  for (let i = 0; i < str.length; i++) {
    if (i >= cursorPosition) {
      return inside;
    }
    if (str.slice(i, i + startChars.length) === startChars) {
      inside = true;
      i = i + startChars.length - 1;
    } else if (str.slice(i, i + endChars.length) === endChars) {
      inside = false;
      i = i + endChars.length - 1;
    }
  }
  return false;
};
export const isBetweenDoubleCurly = (model, position) =>
  isBetweenChars('{{', '}}', model.getLineContent(position.lineNumber), position.column - 1);

export const useFieldSuggestions = projectionName => {
  const {
    accountEntities: { aggProjs },
  } = useAvContext();
  const subProj = SubAutoComplete[projectionName];
  const subTitleFilterOptions = (subProj && aggProjs[subProj].fieldList.SPF.filter(f => !f.visibilityConfig.config.hidden)) || [];
  const titleFilterOptions =
    aggProjs[projectionName].fieldList.EVAL;
  const completions = subProj ? [...titleFilterOptions, ...subTitleFilterOptions] : titleFilterOptions;
  const completionItems = {};
  completions.forEach(item => {
    const { group } = item;
    if (!completionItems[group]) {
      completionItems[group] = [];
    }

    const completionItem = {
      label: item.title,
      kind: languages.CompletionItemKind.Field,
      insertText: item.value, // Use the 'value' property as the inserted value
      range: null, // Set the range if needed, else leave as null
    };
    completionItems[group].push(completionItem);
  });

  return (Object.keys(completionItems) || []).reduce((acc: any[], group) => [...acc, ...completionItems[group]], []);
};

export const useGetProjectionFieldCompletions: ({
  projectionName,
  isInteractive,
}: {
  projectionName: any;
  isInteractive: boolean;
}) => (editor, monaco) => void = ({ projectionName, isInteractive }) => {
  const disposeOptionsRef = useRef<IDisposable>();
  const { aggProjs } = useAvContext().accountEntities;
  const subProj = SubAutoComplete[projectionName];
  const subTitleFilterOptions = (subProj && aggProjs[subProj].fieldList.SPF.filter(f => !f.visibilityConfig.config.hidden)) || [];
  const fieldListType = isInteractive ? 'INTERACTIVE' : 'EVAL';

  const titleFilterOptions = aggProjs[projectionName].fieldList[fieldListType];

  useEffect(() => () => disposeOptionsRef.current?.dispose(), []);
  return (editor, monaco) => {
    const completions = subProj ? [...titleFilterOptions, ...subTitleFilterOptions] : titleFilterOptions;

    function createCompletionItems() {
      const completionItems = {};
      completions.forEach(item => {
        const { group } = item;
        if (!completionItems[group]) {
          completionItems[group] = [];
        }

        const completionItem = {
          label: item.title,
          kind: monaco.languages.CompletionItemKind.Field,
          insertText: item.value, // Use the 'value' property as the inserted value
          range: null, // Set the range if needed, else leave as null
        };
        completionItems[group].push(completionItem);
      });

      return (Object.keys(completionItems) || []).reduce((acc: any[], group) => [...acc, ...completionItems[group]], []);
    }

    // Register the completion provider with Monaco Editor
    const registeredOptions = monaco.languages.registerCompletionItemProvider('python', {
      provideCompletionItems: (model, position) => {
        const lineContent = model.getLineContent(position.lineNumber);
        const prefix = lineContent.substring(0, position.column - 2);
        const suffix = lineContent.substring(position.column);

        // Check if there is '{{' before the current position and '}}' after the current position
        if (!prefix.endsWith('{{') || !suffix.startsWith('}')) {
          return {
            suggestions: [],
          };
        }
        // Call the function to create completions from the array
        const completions1 = createCompletionItems();
        return {
          suggestions: completions1,
        };
      },
    });

    disposeOptionsRef.current = registeredOptions;
  };
};
