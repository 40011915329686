import React from 'react';
import { Box } from '@mui/material';
import { rebranding } from '../rebranding';
import { flex } from './AvThemeProvider';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { ReactComponent as Spinner } from '../assets/Spinner.svg';

const animateStyle = {
  '@keyframes escalate': {
    '0%': { clipPath: 'inset(0 0 100% 0)' },
    '19%': { clipPath: 'inset(0 0 100% 0)' },
    '20%': { clipPath: 'inset(0 0 75% 0)' },
    '34%': { clipPath: 'inset(0 0 75% 0)' },
    '35%': { clipPath: 'inset(0 0 55% 0)' },
    '59%': { clipPath: 'inset(0 0 55% 0)' },
    '60%': { clipPath: 'inset(0 0 35% 0)' },
    '79%': { clipPath: 'inset(0 0 35% 0)' },
    '80%': { clipPath: 'inset(0 0 0 0)' },
    '100%': { clipPath: 'inset(0 0 0 0)' },
  },
  color: theme => theme.palette.colors.complementary[400],
};

const animateSpinner = {
  '@keyframes spin': { '0%': { transform: 'rotate(0deg)' }, '100%': { transform: 'rotate(360deg)' } },
};
interface LoadingProps {
  height?: number;
  duration?: number;
  color?: string;
}

const Loading: React.FC<LoadingProps> = ({ height = rebranding ? 56 : undefined, duration = 1.3, color }) =>
  rebranding ? (
    <Box sx={{ ...flex.center, animateSpinner, height, width: height }}>
      <Spinner
        style={{
          animation: 'spin 1.7s linear infinite',
          color,
          height,
          width: height,
        }}
      />
    </Box>
  ) : (
    <Box sx={animateStyle}>
      <Logo style={{ animation: `escalate ${duration}s infinite`, height, width: height, color }} />
    </Box>
  );

export default Loading;
