import React, { ReactNode, useMemo } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import { rebranding } from '../../rebranding';
import { emptyArray, emptyObject, noop } from '../../utils/Utils';
import AvLegend from '../AvLegend';
import { flex } from '../AvThemeProvider';
import Select from '../Select';
import AvComposedWidget from './AvComposedWidget';
import { ReactComponent as Plus } from '../../assets/Plus.svg';
import { ReactComponent as X } from '../../assets/X.svg';

interface Props {
  data?: any[];
  metrics?: any[];
  updateFilters?: (a: string, list: any[]) => void;
  labels?: { x?: string; y?: string };
  metricOptions: any[];
  additionalAxis?: ReactNode | ReactNode[];
  limit: number;
  disabledTooltipText?: string;
  dateFormat?: string;
  allowSelection?: boolean;
  chartColors?: string[];
}

function KeyMetricsOverTime({
  data = emptyArray,
  dateFormat = 'MMM',
  metrics = emptyArray,
  updateFilters = noop,
  limit,
  labels = emptyObject,
  metricOptions,
  additionalAxis,
  disabledTooltipText,
  allowSelection = true,
  chartColors: customChartColors,
}: Props) {
  const { palette } = useTheme();
  const chartColors =
    customChartColors || rebranding
      ? palette.chartColors
      : [
          palette.colors.primary[300],
          palette.colors.primary[400],
          palette.colors.complementary[400],
          palette.colors.blue[500],
          palette.colors.blue[200],
          palette.colors.yellow[200],
          palette.colors.primary[200],
          palette.colors.blue[600],
          palette.colors.blue[700],
          palette.colors.complementary[650],
          palette.colors.orange[600],
          palette.colors.blue[700],
          palette.colors.neutrals[500],
        ];

  const series = useMemo(
    () =>
      metrics.reduce((acc, m, index) => {
        const metricOption = metricOptions.find(o => o.value === m);
        if (metricOption) {
          const { title: name, axisLabel } = metricOption;
          const isAxisExists = acc.find(a => a.axisLabel === axisLabel);
          acc.push({
            name,
            axisLabel,
            component: (
              <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
                {name}
                {allowSelection && metrics.length > 1 && (
                  <IconButton
                    onClick={() => {
                      updateFilters(
                        'keyMetrics',
                        metrics.filter(metric => metric !== m)
                      );
                    }}>
                    <X />
                  </IconButton>
                )}
              </Box>
            ),
            dataKey: m,
            ...(additionalAxis && index > 0 && { isRightAxis: !isAxisExists }),
            color: chartColors[index],
            type: 'line',
          });
        }
        return acc;
      }, []),
    [metrics, metricOptions, additionalAxis, chartColors, updateFilters]
  );
  const memoOptions = useMemo(() => metricOptions.filter(f => !f.value.includes('exception')), [metricOptions]);
  return (
    <Box sx={{ ...flex.col, height: '100%', gap: 2 }}>
      <Box sx={{ ...flex.itemsCenter, gap: 1 }}>
        {allowSelection && (
          <Select
            variant="outlined"
            type="icon"
            isRequired
            disabled={metrics.length >= limit}
            label={<Plus />}
            disabledTooltipText={disabledTooltipText}
            options={memoOptions}
            size="xSmall"
            shouldCloseOnChange={newVal => newVal?.length >= limit}
            isMultiple
            onChange={v => updateFilters('keyMetrics', v.length ? v : [metricOptions[0].value])}
            value={metrics}
            showSelection={false}
          />
        )}
        <AvLegend isHorizontal series={series} isOutlined={allowSelection} />
      </Box>
      <AvComposedWidget
        isDate
        showLegend={false}
        data={data}
        series={series}
        xAxisKey="date"
        labels={labels}
        additionalAxis={additionalAxis}
        dateFormat={dateFormat}
      />
    </Box>
  );
}

export default KeyMetricsOverTime;
