import { Filter } from '../../../types/filter.types';
import { DeliveryConfig, FileFormat } from '../../Reports/types';
import {
  Bar100PercentHorizontalTypeWidget,
  Bar100PercentVerticalTypeWidget,
  BarHorizontalTypeWidget,
  BarStackedHorizontalTypeWidget,
  BarStackedVerticalTypeWidget,
  BarVerticalTypeWidget,
} from './BarWidget.types';
import { LineTypeWidget } from './LineWidget.types';
import { DonutTypeWidget, PieTypeWidget, PieWithNeedleTypeWidget } from './PieWidget.types';
import { TableTypeWidget } from './TableWidget.types';
import { TextTypeWidget } from './TextWidget.types';
import { ScoreTypeWidget, TileTypeWidget } from './TileWidget.types';

export type BarTypeWidget =
  | BarStackedHorizontalTypeWidget
  | BarStackedVerticalTypeWidget
  | BarHorizontalTypeWidget
  | BarVerticalTypeWidget
  | Bar100PercentHorizontalTypeWidget
  | Bar100PercentVerticalTypeWidget;

export type AllTypeWidgets =
  // PIE
  | DonutTypeWidget
  | PieTypeWidget
  | PieWithNeedleTypeWidget
  // TABLE
  | TableTypeWidget
  // BAR
  | BarTypeWidget
  // LINE
  | LineTypeWidget
  // TILE
  | TileTypeWidget
  | ScoreTypeWidget
  // TEXT
  | TextTypeWidget;

export const publicId = 'PUBLIC';
export enum PermissionType {
  private = 'PRIVATE',
  publicEdit = 'PUBLIC_EDIT',
  publicView = 'PUBLIC_VIEW',
  shared = 'SHARED',
}
export enum UserPermissionType {
  editor = 'EDITOR',
  viewer = 'VIEWER',
}
export type DashboardReportPermission = {
  viewers: string[];
  editors: string[];
  type: PermissionType;
};

export type CustomDashboardDto = {
  id?: string;
  name: string;
  description?: string;
  apps?: string[];
  dashboardReportPermission?: DashboardReportPermission;
  type: ReportsTypes;
  intervalExpression: string;
  createdByUserId?: string;
  updatedByUserId?: string;
  sharedUsers?: string[];
  updatedAt?: string;
  widgets: AllTypeWidgets[];
  active: boolean;
  deliveryConfig?: DeliveryConfig;
  userPermissionType?: UserPermissionType;
  customQueries?: null;
  predefined?: boolean;
  globalFilters?: Filter;
  lastRunAt?: string;
  isIntegrationActive?: any;
};

export enum ReportsTypes {
  TABULAR = 'TABULAR',
  Visual = 'VISUAL',
}

export enum DashboardType {
  CUSTOM_DASHBOARD = 'CUSTOM_DASHBOARD',
  REMEDIATION_DASHBOARD = 'REMEDIATION_DASHBOARD',
  RISK_DASHBOARD = 'RISK_DASHBOARD',
}

export type DashboardExportDto = {
  reportId?: string;
  dashboardType: DashboardType;
  fileFormat: FileFormat;
  searchParams?: string;
};
