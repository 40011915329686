import { add } from 'date-fns';
import { convertPeriodPreset, DateCondition, Expression, Filter, NewRelativeDate, StringConditionType } from '../../types/filter.types';
import { PeriodBehavior } from '../DatePicker/AvDateRangePicker.constants';
import { Preset } from '../DatePicker/types';
import { fromDateMapping, getEndOfDay, RelativeUnit, toDateMapping } from '../DatePicker/utils';

export const boxWidth = 210;
export const selectorWidth = boxWidth - 22;
export const selectFieldWidth = selectorWidth - 40;

export const getExpression = ({
  fieldName,
  typeCondition = ConditionType.string,
  operator = StringConditionType.EQUALS,
  value,
}: {
  fieldName: string;
  typeCondition?: ConditionType;
  operator: string;
  value: any;
}) => {
  if (typeCondition === ConditionType.array) {
    return {
      expression: {
        fieldName: '',
        arrayCondition: {
          resolution: 'ANY' as 'ALL' | 'ANY' | 'COMPLETE',
          underlying: { fieldName, stringCondition: { [operator]: value } },
        },
      },
    };
  }
  return {
    expression: { fieldName, [typeCondition]: { [operator]: value } },
  } as Filter;
};

export const getEmptyExpression = (
  fieldName: string | null = null,
  typeCondition = ConditionType.string,
  defaultNewOperator?,
  isRepeated?
) => {
  const newOperator = defaultNewOperator || defaultOperator[typeCondition];
  const underlying = { fieldName, [typeCondition]: { [newOperator]: defaultValue[typeCondition] } };
  return { expression: isRepeated ? { arrayCondition: { underlying, resolution: 'ANY' } } : underlying } as Filter;
};

export const getCondition = (operand: Filter, typeCondition = ConditionType.string, defaultNewOperator?) => {
  const cond = operand.expression?.arrayCondition?.underlying![typeCondition] || operand.expression?.[typeCondition];
  const resolution = operand.expression?.arrayCondition?.resolution || 'ANY';
  return `${cond ? Object.keys(cond || [])[0] : defaultNewOperator || defaultOperator[typeCondition]}${
    operand.expression?.arrayCondition ? `|${resolution}` : ''
  }`;
};

export const getEmptyFilterExpression = (fieldName: string | null = null, defaultOperator = StringConditionType.CONTAINS) => ({
  and: {
    operands: [{ expression: { fieldName, stringCondition: { [defaultOperator]: '' } } }],
  },
});

const nullOperators = ['empty', 'notEmpty', 'not_empty', 'notExists'].flatMap(op => [op, `${op}|COMPLETE`]);
export const isNullOperator = operator => nullOperators.includes(operator);

const operatorLabelsBase = {
  contains: 'Contains',
  notContains: 'Not Contains',
  starts: 'Starts With',
  ends: 'Ends With',
  equals: 'Equals',
  notEqual: 'Not Equal',
  notIn: 'Not In',
  notExists: 'Does Not Exist',
  notEmpty: 'Is Not Empty',
  empty: 'Is Empty',
  greater: '>',
  greater_equal: '>=',
  less: '<',
  less_equal: '<=',
  gt: '>',
  gte: '>=',
  lt: '<',
  lte: '<=',
  equal: '=',
  between: 'Between',
  relative: 'Relative',
  ipRange: 'IP Range',
  notIpRange: 'Not IP Range',
  cidr: 'CIDR',
  notCidr: 'Not CIDR',
};
export const operatorLabels = Object.entries(operatorLabelsBase).reduce(
  (obj, [key, value]) => ({
    ...obj,
    [key]: value,
    ...(isNullOperator(key) ? { [`${key}|COMPLETE`]: `${value}` } : { [`${key}|ANY`]: `${value} (Any)`, [`${key}|ALL`]: `${value} (All)` }),
  }),
  {} as typeof operatorLabelsBase
);
const ipRegex = /^([0-9]{1,3}\.){3}[0-9]{1,3}$/;
const validateIP = (ip: string) => (ipRegex.test(ip) ? '' : 'Invalid IP');
const getErrorsCIDR = str => {
  const splitStr = str.split('/');
  const errors = [validateIP(splitStr[0]), +splitStr[1] >= 0 && +splitStr[1] <= 32 ? '' : 'Invalid CIDR'];
  return errors.some(Boolean) ? errors.join('/') : '';
};
const getErrorsRange = ({ from, to }) => {
  const errors = { from: validateIP(from), to: validateIP(to) };
  return Object.values(errors).some(Boolean) ? errors : '';
};

export type FilterOption = {
  title: string;
  value: any;
  getErrors?: (value: any) => any;
  inputOptions?: {
    separator: string;
    placeholder1: string;
    placeholder2: string;
    getValue1: (value) => string;
    getValue2: (value) => string;
    onChange1: (first, value) => string;
    onChange2: (second, value) => string;
  };
};

export const supportParserOperatorOptions: Record<string, FilterOption[]> = {
  string: [
    { value: 'equals', title: operatorLabels.equals },
    { value: 'notEqual', title: operatorLabels.notEqual },
    { value: 'contains', title: operatorLabels.contains },
    { value: 'starts', title: operatorLabels.starts },
    { value: 'ends', title: operatorLabels.ends },
    { value: 'empty', title: operatorLabels.empty },
    { value: 'notEmpty', title: operatorLabels.notEmpty },
    { value: 'notContains', title: operatorLabels.notContains },
  ],
};

export const filterOperatorOptions: Record<string, FilterOption[]> = Object.entries({
  string: [
    { value: 'contains', title: operatorLabels.contains },
    { value: 'notContains', title: operatorLabels.notContains },
    { value: 'starts', title: operatorLabels.starts },
    { value: 'ends', title: operatorLabels.ends },
    { value: 'equals', title: operatorLabels.equals },
    { value: 'notEqual', title: operatorLabels.notEqual },
    { value: 'empty', title: operatorLabels.empty },
    { value: 'notEmpty', title: operatorLabels.notEmpty },
  ],
  bool: [
    { value: 'equals', title: operatorLabels.equals },
    { value: 'empty', title: operatorLabels.empty },
    { value: 'notEmpty', title: operatorLabels.notEmpty },
  ],
  date: [
    { value: 'between', title: operatorLabels.between },
    { value: 'relative', title: operatorLabels.relative },
  ],
  number: [
    { value: 'gt', title: operatorLabels.gt },
    { value: 'gte', title: operatorLabels.gte },
    { value: 'lt', title: operatorLabels.lt },
    { value: 'lte', title: operatorLabels.lte },
    { value: 'equals', title: operatorLabels.equal },
    { value: 'empty', title: operatorLabels.empty },
    { value: 'notEmpty', title: operatorLabels.notEmpty },
  ],
  FIX: [
    { value: 'equals', title: operatorLabels.equals },
    { value: 'notEqual', title: operatorLabels.notEqual },
    { value: 'gt', title: operatorLabels.gt },
    { value: 'gte', title: operatorLabels.gte },
    { value: 'lt', title: operatorLabels.lt },
    { value: 'lte', title: operatorLabels.lte },
    { value: 'empty', title: operatorLabels.empty },
    { value: 'notEmpty', title: operatorLabels.notEmpty },
  ],
  IP: [
    { value: 'equals', title: operatorLabels.equals },
    { value: 'notEqual', title: operatorLabels.notEqual },
    // { value: 'notIn', title: operatorLabels.notIn },
    { value: 'gt', title: operatorLabels.gt },
    { value: 'gte', title: operatorLabels.gte },
    { value: 'lt', title: operatorLabels.lt },
    { value: 'lte', title: operatorLabels.lte },
    { value: 'empty', title: operatorLabels.empty },
    { value: 'notEmpty', title: operatorLabels.notEmpty },
    {
      value: 'ipRange',
      title: operatorLabels.ipRange,
      getErrors: getErrorsRange,
      inputOptions: {
        separator: '-',
        placeholder1: '10.0.0.0',
        placeholder2: '0.0.0.255',
        getValue1: ({ from }) => from,
        getValue2: ({ to }) => to,
        onChange1: (from, value) => ({ ...(typeof value === 'object' ? value : {}), from }),
        onChange2: (to, value) => ({ ...(typeof value === 'object' ? value : {}), to }),
      },
    },
    {
      value: 'notIpRange',
      title: operatorLabels.notIpRange,
      getErrors: getErrorsRange,
      inputOptions: {
        separator: '-',
        placeholder1: '10.0.0.0',
        placeholder2: '0.0.0.255',
        getValue1: ({ from }) => from,
        getValue2: ({ to }) => to,
        onChange1: (from, value) => ({ ...value, from }),
        onChange2: (to, value) => ({ ...value, to }),
      },
    },
    {
      value: 'cidr',
      title: operatorLabels.cidr,
      getErrors: getErrorsCIDR,
      inputOptions: {
        separator: '/',
        placeholder1: '10.0.0.0',
        placeholder2: '24',
        getValue1: val => val.split('/')[0],
        getValue2: val => val.split('/')[1],
        onChange1: (first, value) => `${first}/${typeof value === 'string' ? value.split('/')[1] || '' : ''}`,
        onChange2: (second, value) => `${typeof value === 'string' ? value.split('/')[0] || '' : ''}/${second}`,
      },
    },
    {
      value: 'notCidr',
      title: operatorLabels.notCidr,
      getErrors: getErrorsCIDR,
      inputOptions: {
        separator: '/',
        placeholder1: '10.0.0.0',
        placeholder2: '24',
        getValue1: val => val.split('/')[0],
        getValue2: val => val.split('/')[1],
        onChange1: (first, value) => `${first}/${typeof value === 'string' ? value.split('/')[1] || '' : ''}`,
        onChange2: (second, value) => `${typeof value === 'string' ? value.split('/')[0] || '' : ''}/${second}`,
      },
    },
  ].map(operatorDef => ({
    ...operatorDef,
    getErrors: ['empty', 'notEmpty'].includes(operatorDef.value) ? undefined : operatorDef.getErrors || validateIP,
  })),
}).reduce(
  (obj, [type, value]) => ({
    ...obj,
    [type]: value,
    [`${type}[]`]: value.flatMap(op =>
      isNullOperator(op.value)
        ? [{ ...op, value: `${op.value}|COMPLETE`, title: `${op.title}` }]
        : [
            { ...op, value: `${op.value}|ANY`, title: `${op.title} (Any)` },
            { ...op, value: `${op.value}|ALL`, title: `${op.title} (All)` },
          ]
    ),
  }),
  {}
);

export enum ConditionType {
  string = 'stringCondition',
  date = 'dateCondition',
  number = 'numberCondition',
  bool = 'boolCondition',
  IP = 'ipCondition',
  FIX = 'fixCondition',
  array = 'arrayCondition',
}

export const booleanOptions = [
  { title: 'True', value: true },
  { title: 'False', value: false },
  { title: 'Not defined', value: null },
];
export const booleanOptions2 = [
  { title: 'True', value: true },
  { title: 'False', value: false },
];

export const defaultOperator = {
  [ConditionType.string]: 'contains',
  [ConditionType.date]: 'between',
  [ConditionType.number]: 'gt',
  [ConditionType.bool]: 'equals',
  [ConditionType.IP]: 'equals',
};
export const defaultValue = {
  [ConditionType.string]: '',
  [ConditionType.date]: { values: [new Date(), new Date()] },
  [ConditionType.number]: 0,
  [ConditionType.bool]: true,
  [ConditionType.IP]: '',
};

export const getStringConditionFromDatePicker = operand => {
  if (operand.value === null) {
    return { stringCondition: { empty: {} } };
  }
  if (!operand.value) {
    return {};
  }
  if (operand.preset) {
    return {
      dateCondition: {
        relative: {
          unit: RelativeUnit[operand.preset.period.toUpperCase()],
          value: operand.preset.count,
          periodBehaviour: operand.preset.type.toUpperCase(),
        },
      },
    };
  }
  return {
    dateCondition: {
      between: { values: [operand.value.from, operand.value.to] },
    },
  };
};

export const getDatePickerFromQueryObject = (filterExp: Filter) => {
  // @ts-ignore
  const { dateCondition } = filterExp!.expression || Object.values(filterExp)[0]?.operands?.[0]?.expression || {};
  if (dateCondition) {
    return getDateFromFilter(dateCondition);
  }
  return undefined;
};

const getFrom = relative => {
  if ([PeriodBehavior.last, PeriodBehavior.previous].includes(relative.periodBehaviour)) {
    return fromDateMapping[relative.periodBehaviour][relative.unit](relative.value);
  }

  return new Date();
};

const getTo = relative => {
  if (relative.periodBehaviour === PeriodBehavior.last) {
    return getEndOfDay();
  }

  if (relative.periodBehaviour === PeriodBehavior.previous) {
    return toDateMapping[relative.periodBehaviour][relative.unit];
  }

  return add(new Date(), { [relative.unit.toLowerCase()]: relative.value });
};

export const getDateFromFilter = (dateCondition: DateCondition) => {
  const { relative } = dateCondition;
  if (relative) {
    return {
      value: {
        from: getFrom(relative),
        to: getTo(relative),
      },
      preset: {
        type: (relative as NewRelativeDate).periodBehaviour.toLowerCase(),
        count: relative.value,
        period: convertPeriodPreset[relative.unit],
      } as Preset,
    };
  }

  if (dateCondition.between) {
    return {
      value: {
        from: dateCondition.between.values[0],
        to: dateCondition.between.values[1],
      },
    };
  }

  return {
    value: {
      from: new Date(),
      to: new Date(),
    },
  };
};

enum TransformOperator {
  greater = 'gt',
  greater_equal = 'gte',
  less = 'lt',
  less_equal = 'lte',
}
export const getOperator = operator => TransformOperator[operator] || operator;

export const getFilterOptionsWithLegacy = (options: FilterOption[], operator: string) =>
  options.find(({ value }) => value === getOperator(operator))
    ? options
    : [{ value: operator, title: `${operatorLabelsBase[operator]} (Legacy)` }, ...options];

export const defaultIngressExpression = {
  and: {
    operands: [
      getExpression({
        fieldName: 'asset.sources.source_names', // TODO - understand how to make it generic - use the projection | self alias or maybe use the second operand
        typeCondition: ConditionType.array,
        operator: StringConditionType.CONTAINS,
        value: null,
      }),
      getEmptyExpression('asset.sources.owner_id'),
    ],
  },
};

export const getSourcesExp = (source, projectionName = 'asset') =>
  getExpression({
    fieldName: `${projectionName}.sources.source_names`,
    typeCondition: ConditionType.array,
    operator: StringConditionType.CONTAINS,
    value: source,
  });

const isIngressField = (expression?: Expression): boolean => {
  if (expression?.arrayCondition) {
    return expression.arrayCondition?.underlying.fieldName?.includes('.sources.') || false;
  }
  return expression?.fieldName?.includes('.sources.') || false;
};

export const isIngressExp = (innerFilter: Filter) =>
  innerFilter.and && innerFilter.and.operands.length === 2 && isIngressField(innerFilter.and.operands[0].expression);
