import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '../components/Permissions';
import { useAvContext } from '../context/AvContextProvider';
import { PAGE_PATHS, Permission } from '../types';
import { FactorKind } from '../types/executionRules.types';
import avLazy from '../utils/AvLazy';

const FactorRules = avLazy(() => import('../views/FactorRules/FactorRules'));
const EditFactorRules = avLazy(() => import('../views/FactorRules/EditFactorRules'));
const BuiltInFactorRulesRoute = () => {
  const {
    userPermissions: { hasAllowedPermission },
  } = useAvContext();
  const path = PAGE_PATHS.GLOBAL_FACTOR_RULES;
  return (
    <Route path={path}>
      <Route
        path=""
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path })}>
            <FactorRules kind={FactorKind.BUILT_IN} />
          </ProtectedRoute>
        }
      />
      <Route
        path="create/"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.CREATE })}>
            <EditFactorRules kind={FactorKind.BUILT_IN} />
          </ProtectedRoute>
        }
      />
      <Route
        path="edit/:id"
        element={
          <ProtectedRoute isAllowed={hasAllowedPermission({ path, permission: Permission.UPDATE })}>
            <EditFactorRules kind={FactorKind.BUILT_IN} isEditMode />
          </ProtectedRoute>
        }
      />
    </Route>
  );
};

export default BuiltInFactorRulesRoute;
