/* eslint-disable custom-rules/no-import-meta-env */

import projectEnvVariables, { ProjectEnvVariablesType } from './env';

const isPlaceholder = (value: any) => typeof value === 'string' && value.startsWith('${') && value.endsWith('}');

// Returning the variable value from runtime or obtained as a result of the build
const getProjectEnvVariables = (): { envVariables: ProjectEnvVariablesType } => {
  const envVariables = {} as ProjectEnvVariablesType;
  Object.keys(import.meta.env).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(import.meta.env, key)) {
      const value = projectEnvVariables[key as keyof ProjectEnvVariablesType] || import.meta.env[key as keyof ImportMetaEnv];
      envVariables[key as keyof ProjectEnvVariablesType] = isPlaceholder(value) ? import.meta.env[key as keyof ImportMetaEnv] : value;
    }
  });

  return { envVariables };
};

const { envVariables } = getProjectEnvVariables();

export default envVariables;
