import React from 'react';
import { alpha, Theme } from '@mui/material';
import { Bar, Cell, Line } from 'recharts';
import { isPercentageMetric } from '../../utils/dashboardDataUtils';
import { isExportMode } from '../../utils/exportDashboards.utils';
import { isNullOrUndefined } from '../../utils/Utils';

// eslint-disable-next-line import/prefer-default-export
export const componentType = {
  bar: ({
    props,
    onSelect,
    selected = [],
    data,
    hoveredCell = {},
    setHoveredCell = () => {},
  }: {
    props: any;
    onSelect?: (cell: any) => undefined;
    selected: any[];
    data;
    hoveredCell;
    setHoveredCell: (arg: { name: string; dataKey: any } | undefined) => void;
  }) => (
    <Bar
      yAxisId={props.yAxisId || isPercentageMetric(props.dataKey) || props.isRightAxis ? 'right' : 'left'}
      key={props.dataKey}
      name={props.dataKey || props.name}
      dataKey={props.dataKey}
      barSize={20}
      fill={props.color}
      onClick={d => onSelect?.({ d, dataKey: props.dataKey, widgetData: data })}
      hide={props.hide}
      minPointSize={props.minPointSize || 0}
      isAnimationActive={!isExportMode() && props.animation}
      {...(props.stackId && { stackId: props.stackId })}>
      {props?.children ||
        (props.color !== 'transparent' &&
          data.map(entry => (
            <Cell
              key={entry.name}
              onMouseEnter={() => onSelect && setHoveredCell({ name: entry.name, dataKey: props.dataKey })}
              onMouseLeave={() => setHoveredCell(undefined)}
              onBlur={() => setHoveredCell(undefined)}
              cursor={onSelect ? 'pointer' : 'default'}
              filter={
                hoveredCell.name === entry.name && hoveredCell.dataKey === props.dataKey
                  ? `drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.60)`
                  : undefined
              }
              fill={alpha(
                props.color,
                selected.some(v => v.name === entry.name && (!v.dataKey || v.dataKey === props.dataKey)) || selected.length === 0 ? 1 : 0.15
              )}
            />
          )))}
    </Bar>
  ),
  line: ({ props, theme }: { props: any; theme: Theme }) => (
    <Line
      yAxisId={props.yAxisId || isPercentageMetric(props.dataKey) || props.isRightAxis ? 'right' : 'left'}
      type={props.lineType || 'monotone'}
      key={props.dataKey}
      name={props.name || props.dataKey}
      dataKey={props.dataKey}
      strokeWidth={3}
      dot={!isNullOrUndefined(props.dot) ? props.dot : { r: 0 }}
      isAnimationActive={!isExportMode() && props.animation}
      hide={props.hide}
      activeDot={
        !isNullOrUndefined(props.activeDot)
          ? props.activeDot
          : { fill: theme.palette.white.main, stroke: props.color, strokeWidth: 3, r: 4 }
      }
      stroke={props.color}
    />
  ),
};
