import React from 'react';
import { useTheme } from '@mui/material';
import AvTooltip from '../AvTooltip';

interface Props {
  x?: number;
  y?: number;
  payload?: { [key: string]: any };
  xOffset: number;
  yOffset?: number;
  shouldRotateTicks?: boolean;
  height?;
  angle?;
  textAnchor?;
  tickFormatter?;
}

function CustomTick({ x = 0, y = 0, payload, xOffset = 0, yOffset = 8, shouldRotateTicks = false, ...rest }: Props) {
  const maxWidth = shouldRotateTicks ? 85 : 100;
  const theme = useTheme();
  return (
    <foreignObject
      x={x - xOffset}
      y={y - yOffset}
      width={maxWidth}
      height={rest.height}
      transform={`rotate(${rest.angle}, ${x + 35}, ${y + maxWidth - 28})`}>
      <AvTooltip
        sx={{
          color: theme.palette.colors.neutrals[500],
          fontSize: '12px',
          pr: 1,
          textAlign: rest.textAnchor,
        }}>
        {rest.tickFormatter(payload?.value)}
      </AvTooltip>
    </foreignObject>
  );
}

export default CustomTick;
