import { BaseWidget, WidgetCategory, WidgetDefinition } from './base.types';

export enum BarSubType {
  BarVertical = 'BAR_VERTICAL',
  StackedVertical = 'STACKED_VERTICAL',
  Percentage100_Vertical = 'PERCENTILE_VERTICAL',
  BarHorizontal = 'BAR_HORIZONTAL',
  StackedHorizontal = 'STACKED_HORIZONTAL',
  Percentage100_Horizontal = 'PERCENTILE_HORIZONTAL',
}

export enum DisplayModeOptions {
  SCROLL = 'SCROLL',
  FIT = 'FIT',
}

export enum BarLegendPosition {
  Top = 'TOP',
  Bottom = 'BOTTOM',
  None = 'NONE',
}

export type BarLegend = {
  position: BarLegendPosition;
  margin?: { top?: number; bottom?: number; left?: number; right?: number };
};

export type BarCategoryDefinition = {
  fieldsPalette: Record<string, string>;
  legend: BarLegend;
  rowsColumnSwitch: boolean;
  displayMode: DisplayModeOptions;
};

export type BarWidget = BaseWidget & {
  category: WidgetCategory.Bar;
  type: BarSubType;
  definition: WidgetDefinition & { custom: BarCategoryDefinition };
};

export type BarVerticalTypeWidget = BarWidget & {
  type: BarSubType.BarVertical;
};

export type BarHorizontalTypeWidget = BarWidget & {
  type: BarSubType.BarHorizontal;
};

export type BarStackedVerticalTypeWidget = BarWidget & {
  type: BarSubType.StackedVertical;
};

export type BarStackedHorizontalTypeWidget = BarWidget & {
  type: BarSubType.StackedHorizontal;
};

export type Bar100PercentVerticalTypeWidget = BarWidget & {
  type: BarSubType.Percentage100_Vertical;
};

export type Bar100PercentHorizontalTypeWidget = BarWidget & {
  type: BarSubType.Percentage100_Horizontal;
};

export type BarCategoryWidgetTypes =
  | BarStackedHorizontalTypeWidget
  | BarStackedVerticalTypeWidget
  | BarHorizontalTypeWidget
  | BarVerticalTypeWidget
  | Bar100PercentHorizontalTypeWidget
  | Bar100PercentVerticalTypeWidget;
