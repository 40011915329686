import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getEmptyFilterExpression } from '../../../components/filters/Utils';
import { FilterMode, FilterType, PolicyType } from '../../../types/executionRules.types';
import { setNestedValue } from '../../../utils/Utils';
import { categoryToDefaultFilterMap } from '../utils';

const PoliciesToggleButton = ({ setNewData, newData }: { setNewData: (PolicyType) => void; newData: PolicyType }) => {
  const { filterType } = newData.clientConfig.policyScenario;
  return (
    <ToggleButtonGroup
      size="xSmall"
      color="white"
      onChange={() => {
        setNewData(
          setNestedValue(
            'clientConfig.policyScenario',
            newData,
            filterType !== FilterType.ADVANCED
              ? {
                  filter: getEmptyFilterExpression('asset.name'),
                  filterType: FilterType.ADVANCED,
                }
              : categoryToDefaultFilterMap[newData.metadata.type].policyScenario
          )
        );
      }}
      value={filterType === FilterType.ADVANCED ? FilterMode.ADVANCED : FilterMode.SIMPLE}
      sx={{ color: theme => theme.palette.colors.neutrals[600], flexShrink: 0, height: '24px', mr: -1 }}>
      <ToggleButton value={FilterMode.SIMPLE}>Simple</ToggleButton>
      <ToggleButton value={FilterMode.ADVANCED}>Advanced</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default PoliciesToggleButton;
