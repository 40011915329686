import React from 'react';
import { editor } from 'monaco-editor';
import { useGetProjectionFieldCompletions } from '../views/Settings/GroupingRules/hooks';
import CodeEditor from './CodeEditor';

const DEFAULT_EDITOR_HEIGHT = 40;
const AvSchemaFieldCompletionEditor = ({
  projectionName,
  onChange,
  value,
  height = DEFAULT_EDITOR_HEIGHT,
  label,
  isInteractive = false,
  error,
}: {
  projectionName: string;
  onChange: (value) => void;
  value: string | undefined;
  height?: number;
  label?: string;
  isInteractive?: boolean;
  error?: string;
}) => {
  const onEditorMount = useGetProjectionFieldCompletions({ projectionName, isInteractive });
  const monacoProps: Partial<editor.IStandaloneEditorConstructionOptions> = {
    lineNumbers: 'off',
    lineDecorationsWidth: 'off',
    overviewRulerLanes: 0,
    hideCursorInOverviewRuler: true,
    scrollbar: {
      vertical: 'hidden',
    },
    fixedOverflowWidgets: false,
    overviewRulerBorder: false,
    renderLineHighlight: 'none',
    lineNumbersMinChars: 0,
    glyphMargin: false,
    folding: false,
    wordWrap: height > DEFAULT_EDITOR_HEIGHT ? 'on' : 'off',
  };
  return (
    <CodeEditor
      label={label}
      height={height}
      language="python"
      style={{ p: 1 }}
      onChange={onChange}
      value={value || ''}
      onMount={onEditorMount}
      options={monacoProps}
      error={error}
    />
  );
};

export default AvSchemaFieldCompletionEditor;
