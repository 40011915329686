import React, { ReactNode, useMemo, useState } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { alpha, Box, SxProps, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { BarChart, CartesianGrid, ComposedChart, ResponsiveContainer, Tooltip, XAxis, XAxisProps, YAxis, YAxisProps } from 'recharts';
import { CategoricalChartProps } from 'recharts/types/chart/generateCategoricalChart';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { ContentType } from 'recharts/types/component/Tooltip';
import { useAvContext } from '../../context/AvContextProvider';
import { FeatureFlags } from '../../types';
import { isPercentageMetric } from '../../utils/dashboardDataUtils';
import { abbreviateNumber, normalize100PercentData } from '../../utils/Utils';
import AvLegend from '../AvLegend';
import { flex } from '../AvThemeProvider';
import { componentType } from './constants';
import CustomTick from './CustomTick';
import CustomTooltip from './CustomTooltip';
import { getHorizontalWidth } from './layout.components';
import { ChartClickInteraction } from './types';

interface Props {
  data: any[];
  series: any[];
  isDate?: boolean;
  xAxisKey: string;
  showLegend?: boolean;
  labels?: { x?: string; y?: string };
  additionalAxis?: ReactJSXElement | ReactNode | ReactNode[];
  chartProps?: Partial<Pick<Readonly<CategoricalChartProps>, keyof CategoricalChartProps>>;
  defaultAxisProps?: {
    x?: XAxisProps;
    xLabel?: object;
    y?: YAxisProps;
  };
  tooltipProps?: {
    showContentLegend?: boolean;
    metricsToHide?: string[];
    showTitle?: boolean;
    showTitleLegend?: boolean;
    metricFormatter?: (value: string) => string | (() => string);
  };
  dateFormat?: string;
  getTooltipContent?: ContentType<ValueType, NameType>;
  sx?: SxProps;
  className?: string;
  selected?: any[];
  onSelect?: (v: any) => void;
  tooltipComponentProps?: { shared?: boolean };
  clickInteractions?: ChartClickInteraction[];
  responsiveContainerProps?: { sx?: SxProps; width?: number | string; height?: number | string };
}

function AvComposedWidget({
  data: d = [],
  series = [],
  isDate,
  xAxisKey,
  showLegend = true,
  labels = {},
  additionalAxis,
  chartProps,
  defaultAxisProps = { x: {}, y: {}, xLabel: {} },
  tooltipProps = { showContentLegend: true, metricsToHide: [], showTitleLegend: false },
  dateFormat,
  getTooltipContent,
  sx = {},
  className,
  selected = [],
  onSelect,
  tooltipComponentProps = {},
  clickInteractions,
  responsiveContainerProps = {},
}: Props) {
  const { featureFlags } = useAvContext();
  const [temporaryClickedCell, setTemporaryClickedCell] = useState<{ d: any; dataKey: any }>();
  const series100Percent = series.filter(({ is100Percent }) => is100Percent);
  const is100Percent = series100Percent.length;
  const data = useMemo(() => (is100Percent ? normalize100PercentData(d, series) : d), [d, series]);
  const theme = useTheme();
  const [hoveredCell, setHoveredCell] = useState();
  const rightAxisMetrics = useMemo(() => series.filter(s => isPercentageMetric(s.dataKey)), [series]);
  const isBar = series.every(({ type }) => type === 'bar');
  const isHorizontal = chartProps?.layout !== 'vertical';
  const shouldRotateTicks = data.length > 6;

  const tickRotationProps = {
    textAnchor: 'end',
    angle: -45,
    height: 80,
    tick: (
      <CustomTick
        shouldRotateTicks={shouldRotateTicks}
        xOffset={featureFlags[FeatureFlags.EnableScrollWidget] ? 24 : 20}
        yOffset={featureFlags[FeatureFlags.EnableScrollWidget] ? 14 : 8}
      />
    ),
  };

  const onCellSelection = cell => {
    if (clickInteractions && !selected.length) {
      const availableCellClickInteractions = clickInteractions.filter(v => v.isEnabledForCell(cell));
      if (availableCellClickInteractions.length === 1) {
        availableCellClickInteractions[0].onClick(cell);
      } else if (availableCellClickInteractions.length) {
        setTemporaryClickedCell(cell);
      }
      return undefined;
    }
    onSelect?.(cell);
    return undefined;
  };

  const tickFormatterX = isDate ? date => (date ? format(new Date(date), dateFormat || 'MMM') : '') : v => v;
  const tickFormatterY = tick => `${abbreviateNumber(tick)}${is100Percent ? '%' : ''}`;

  const horizontalWidth = useMemo(() => getHorizontalWidth(data), [data]);
  const content = (
    <>
      <CartesianGrid stroke={theme.palette.colors.neutrals[300]} vertical={!isHorizontal} horizontal={isHorizontal} />
      <XAxis
        label={{
          ...(labels.x && {
            value: labels.x,
            style: { textAnchor: 'bottom', fill: theme.palette.colors.neutrals[600] },
            dy: 10,
            position: 'center',
            offset: 0,
            ...defaultAxisProps.xLabel,
          }),
        }}
        dy={10}
        dataKey={isHorizontal ? xAxisKey : undefined}
        type={isHorizontal ? 'category' : 'number'}
        axisLine={false}
        tickLine={false}
        tickCount={5}
        {...(shouldRotateTicks && tickRotationProps)}
        tickFormatter={isHorizontal ? tickFormatterX : tickFormatterY}
        {...defaultAxisProps.x}
      />
      <YAxis
        label={{
          ...(labels.y && {
            value: labels.y,
            style: { textAnchor: 'middle', fill: theme.palette.colors.neutrals[600] },
            angle: -90,
            position: 'left',
            offset: isHorizontal ? 0 : horizontalWidth,
          }),
        }}
        tickMargin={15}
        yAxisId="left"
        tickCount={5}
        dataKey={isHorizontal ? undefined : xAxisKey}
        type={isHorizontal ? 'number' : 'category'}
        orientation="left"
        axisLine={false}
        tickLine={false}
        allowDecimals={false}
        tickFormatter={isHorizontal ? tickFormatterY : tickFormatterX}
        {...(isHorizontal ? {} : { tick: <CustomTick xOffset={90} /> })}
        {...defaultAxisProps.y}
      />
      {additionalAxis}
      {!!rightAxisMetrics?.length && (
        <YAxis
          yAxisId="right"
          tickCount={5}
          tickMargin={15}
          orientation="right"
          axisLine={false}
          tickLine={false}
          tickFormatter={tick => `${tick}%`}
          domain={[0, 1]}
          allowDecimals={false}
        />
      )}
      <Tooltip
        cursor={{ fill: alpha(theme.palette.colors.neutrals[300], 0.5) }}
        {...(clickInteractions?.some(v => v.isEnabledForCell(temporaryClickedCell))
          ? { active: !!hoveredCell || !!temporaryClickedCell, trigger: temporaryClickedCell ? 'click' : 'hover' }
          : {})}
        content={
          getTooltipContent ||
          /* eslint-disable-next-line react/no-unstable-nested-components */
          (({ payload } = {}) => (
            <CustomTooltip
              payload={payload}
              isDate={isDate}
              {...tooltipProps}
              dateFormat={dateFormat}
              series100Percent={series100Percent}
              clickInteractions={clickInteractions}
              areClickOptionsVisible={!!temporaryClickedCell}
              setAreClickOptionsVisible={setTemporaryClickedCell}
            />
          ))
        }
        wrapperStyle={{ zIndex: theme.zIndex.modal, pointerEvents: 'auto', overflow: 'auto', maxHeight: '300px' }}
        {...tooltipComponentProps}
      />
      {series.map(s =>
        componentType[s.type]({
          props: s,
          theme,
          selected,
          onSelect: onCellSelection,
          data,
          hoveredCell: temporaryClickedCell ? { ...temporaryClickedCell.d, dataKey: temporaryClickedCell.dataKey } : hoveredCell,
          setHoveredCell: v => setHoveredCell(v),
        })
      )}
    </>
  );

  return (
    <Box
      sx={{ ...flex.col, height: '100%', gap: 2, ...(Array.isArray(sx) ? sx : [sx]) }}
      onMouseLeave={() => setTemporaryClickedCell(undefined)}>
      {showLegend && <AvLegend series={series} isHorizontal />}
      <Box sx={{ flex: 1, overflow: 'hidden', ...responsiveContainerProps.sx }}>
        <ResponsiveContainer
          className={className}
          width={responsiveContainerProps.width ?? '100%'}
          height={responsiveContainerProps.height ?? '100%'}>
          {isBar ? (
            <BarChart {...chartProps} data={data}>
              {content}
            </BarChart>
          ) : (
            <ComposedChart {...chartProps} data={data}>
              {content}
            </ComposedChart>
          )}
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}
export default AvComposedWidget;
